import { Component, OnInit, ViewChild,Pipe, PipeTransform } from '@angular/core';
import { NfsService } from '../services/nfs.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';  

import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graficocontasapagar',
  templateUrl: './graficocontasapagar.component.html',
  styleUrls: ['./graficocontasapagar.component.css']
})
export class GraficocontasapagarComponent implements OnInit {

  @ViewChild('chartBillsToPay')
  chartBillsToPay: BaseChartDirective;

  currentUser: any;
  suppliers: any;
  loading = false;
  NFs: any;
  totalValue: number = 0;
  start: any;
  end: any;
  until: any;
  from: any;
  
  barChartLabelsBillsToPay: string[] = [];
  barChartDataBillsToPay: any[] = [
      { data: [], label: 'Valor' },
    ];

  BillsPayload = {
    username: '',
    companyCode: [],
    costCenter: null,
    id: null,
    dueDateFrom: '',
    dueDateUntil: '',
    status: 0
  };

  //Gráfico barras Contas a Pagar
  public barChartOptionsBillsToPay: any = {
    scaleShowVerticalLines: false,
    responsive: true
  }; 
  
  barChartTypeBillsToPay: string = 'bar';
  barChartLegendBillsToPay: boolean = false;  

  chartColorBillsToPay: any[] = [
    { backgroundColor: 'rgba(30, 169, 224, 0.8)' }
  ];

  dictBillsToPay = [];

  public formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  constructor(private NfsService: NfsService,
    private alertService: AlertService,) {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));
  }

  ngOnInit() {
    this.graphBillsToPay();
  }

  private graphBillsToPay() {
    this.loading = true;
    
    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    if (this.currentUser.type == 'UL') {
      //UL = Usuario Loja           
      //Prctr = codigo do centro de lucro (id da loja)
      this.BillsPayload.costCenter = this.currentUser.cost_center;
    }
    else {
      //UE = Usuario fornecedor    
      //Lifnr = codigo do forncedor 
      this.suppliers.map((item) => {
        this.BillsPayload.companyCode.push(item.id);
      });
      this.BillsPayload.id = this.suppliers.company;
    }
    this.BillsPayload.username = this.currentUser.username;

    let today = new Date();

    //Datas para selecionar Contas A pagar de 1 mês atrás até hoje
    let until_billpayload = moment(today).format("YYYY-MM-DD");
    let from_billpayload = moment(today).subtract(1, 'months').format("YYYY-MM-DD");

    //Datas para fazer subselect em contas a pagar com data de vencimento 5 dias atras e 3 dias apra frente e partir de hoje
    this.until = moment(today).add(5, 'days').format("YYYY-MM-DD");
    this.from = moment(today).subtract(3, 'days').format("YYYY-MM-DD");
    //Convertendo data para comparação
    let Dfrom = Date.parse(this.from);
    let Duntil = Date.parse(this.until);

    this.BillsPayload.dueDateFrom = from_billpayload;
    this.BillsPayload.dueDateUntil = until_billpayload;
    
     this.NfsService.getNFs(this.BillsPayload)
      .then(
        (data) => {
          this.NFs = data;
          if (this.NFs) {

              var filteredArray = new Array();                
              
              //Sub Filtro para retonrar daddos conforme data de vencimento
              this.NFs.items.forEach(nfs => {  

                  //Transformando data formato texto brasileiro para americano dd/MM/YY -> YYYY-MM-DD
                  let splitdtvenc = nfs.due_date.split("/");
                  let dtvencnfeua = splitdtvenc[2]+"-"+splitdtvenc[1]+"-"+splitdtvenc[0];
                  var resultdtvenc = Date.parse(dtvencnfeua); 

                  if ((resultdtvenc >= Dfrom && resultdtvenc <= Duntil)) { 
                      // Uhull dentro do range 
                      filteredArray.push(nfs);           
                  }  
              });     

              this.NFs.items = filteredArray;
              //Deixar o gráfico crescente Menor para Maior
              this.NFs.items = this.transform(this.NFs.items,"due_date",true);

              this.NFs.items.map((item) => {
                this.barChartLabelsBillsToPay.push(item.due_date);

                item.totalValue = 0;

                item.rows.map((row) => {
                  item.totalValue += row.dmbtr;
                });

                this.barChartDataBillsToPay[0].data.push(item.totalValue.toFixed(2));
               
              });

            if (filteredArray.length == 0) {

              //Define os dias que foram pesquisados e valores == 0 
              this.start = new Date(this.from);
              this.start.setDate(this.start.getDate() + 1);

              this.end = new Date(this.until);
              this.end.setDate(this.end.getDate() + 1);

              while (this.start <= this.end) {
                this.barChartLabelsBillsToPay.push(moment(this.start).format(("DD/MM/YYYY")));
                this.barChartDataBillsToPay[0].data.push(0);
                var newDate = this.start.setDate(this.start.getDate() + 1);
                this.start = new Date(newDate);
              }


            }

            //Define dicionário para adicionar na tabela ao lado do gráfico
            for (let i = 0; i < this.barChartLabelsBillsToPay.length; i++) {
              this.dictBillsToPay.push({
                key: this.barChartLabelsBillsToPay[i],
                value: this.formatter.format(this.barChartDataBillsToPay[0].data[i])
              });
            }

            this.chartBillsToPay.chart.update();
            this.loading = false;

          } else {
            this.alertService.error("Erro ao buscar dados.");
            this.loading = false;
          }

        },
        error => {
          this.alertService.error("Erro ao buscar dados.");
          this.loading = false;
        });

  }

  transform(array, orderBy, asc = true){
 
    if (!orderBy || orderBy.trim() == ""){
      return array;
    } 

    //ascending
    if (asc){
      return Array.from(array).sort((item1: any, item2: any) => { 
        return this.orderByComparator(item1[orderBy], item2[orderBy]);
      });
    }
    else{
      //not asc
      return Array.from(array).sort((item1: any, item2: any) => { 
        return this.orderByComparator(item2[orderBy], item1[orderBy]);
      });
    }

  }
  
  orderByComparator(a:any, b:any):number{
 
    if((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))){
      //Isn't a number so lowercase the string to properly compare
      if(a.toLowerCase() < b.toLowerCase()) return -1;
      if(a.toLowerCase() > b.toLowerCase()) return 1;
    }
    else{
      //Parse strings as numbers to compare properly
      if(parseFloat(a) < parseFloat(b)) return -1;
      if(parseFloat(a) > parseFloat(b)) return 1;
     }

    return 0; //equal each other
}

}
