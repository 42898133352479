import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe((message) => this.message = message);

        this.alertService.getMessage().pipe(
            debounceTime(5000)
        ).subscribe(() => this.message = null);

    }

    ngOnDestroy() {
        if(this.subscription){
            this.subscription.unsubscribe();
        } 
    }   
}