import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { faFileExcel, faHandHoldingUsd, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { NfsService } from '../services/nfs.service';
import { AlertService } from '../services/alert.service';
import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { ServerDataSource, Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import * as moment from 'moment';
import { DetalhevalordevolucaoComponent } from '../modaldetalhes/detalhevalordevolucao/detalhevalordevolucao.component';
import { DetalheirfComponent } from '../modaldetalhes/detalheirf/detalheirf.component';
import { DetalheocorrenciaComponent } from '../modaldetalhes/detalheocorrencia/detalheocorrencia.component';
import { DownloadcomprovanteComponent } from './downloadcomprovante/downloadcomprovante.component';
import { Router } from '@angular/router';
import { parseDate } from 'ngx-bootstrap/chronos/public_api';
import { AuthenticationService } from '../services/auth.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'app-contasapagar',
  templateUrl: './contasapagar.component.html',
  styleUrls: ['./contasapagar.component.css']
})


export class ContasapagarComponent implements OnInit {

  @ViewChild('grdTags') grdTags;

  faFileExcel = faFileExcel;
  faHandHoldingUsd = faHandHoldingUsd;
  faFileInvoiceDollar = faFileInvoiceDollar;

  loading = false;
  showDateError = false;
  showpselectpag = false;

  currentUser: any;
  suppliers: any;

  hideCols: any;
  keys: any;
  viewShowInfoAdc = false;

  source: ServerDataSource;

  payload = {
    username: '',
    companyCode: [],
    costCenter: null,
    id: null,
    dueDateFrom: '',
    dueDateUntil: '',
    DownloadExcel: false,
    status: null
  };

  dataResponse: any;
  totalPaid: any;
  totalOpen: any;

  itemRows: any = [];

  filterForm: FormGroup;
  permissions: any;
  pagination: boolean = false;
  pageOptions: any[];
  selectPage: boolean = false;

  //#region Características multiSelect

  // Default selection
  optionsModel: number[];
  myOptions: IMultiSelectOption[] = [];

  // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true,
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Todos Selecionados',
    uncheckAll: 'Desmarque todos',
    checked: 'item selecionado',
    checkedPlural: 'itens selecionados',
    searchPlaceholder: '',
    searchEmptyResult: 'Nada encontrado...',
    searchNoRenderText: 'Digite na caixa de pesquisa para ver os resultados ...',
    defaultTitle: 'Selecione o CNPJ',
    allSelected: 'Todos selecionados',
  };
  //#endregion

  //#region data-table
  settings;
  mySettingsTable = {
    actions: false,
    columns: {
      'status': {
        type: 'html',
        title: 'Status',
        valuePrepareFunction: (cell, row) => {
          return this.getHtmlForCellStatus(row.status)
        },
        isSelected: true,
        filter: false,
      },
      operacao: {
        title: 'Operação',
        isSelected: true,
        filter: true,
      },
      stcd1: {
        title: 'CNPJ',
        isSelected: true,
        filter: true,
      },
      name1: {
        title: 'Razão Social',
        isSelected: true,
        filter: true,
      },
      xblnr: {
        title: 'Nota Fiscal',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.xblnr != null || row.xblnr != undefined)
            return "<center>" + row.xblnr + "</center>"
        },
        isSelected: true,
        filter: true,
      },
      bldat: {
        title: 'DT NF',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.bldat + "</center>" },
        isSelected: true,
        filter: true,
      },
      budat: {
        title: 'DT Recebimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.budat + "</center>" },
        isSelected: true,
        filter: true,
      },
      dmbtr_format: {
        title: 'R$ Bruto',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<div class='tright'> R$ " + row.dmbtr_format + "</div>"; },
        isSelected: true,
        filter: true,
      },
      'impostos': {
        title: 'IRF',
        type: 'custom',
        renderComponent: DetalheirfComponent,
        isSelected: true,
        filter: true,
      },
      'dmbtr_crd': {
        title: 'R$ Devolução',
        type: 'custom',
        renderComponent: DetalhevalordevolucaoComponent,
        isSelected: true,
        filter: true,
      },
      faedt: {
        title: 'DT Vencimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.faedt + "</center>" },
        compareFunction: (direction: any, a: any, b: any) => {
          let first = typeof a === 'string' ? Date.parse(a) : a;
          let second = typeof b === 'string' ? Date.parse(b) : b;

          if (first < second)
            return -1 * direction;
          else
            return direction;
        },
        isSelected: true,
        filter: true,
      },
      augdt: {
        title: 'DT Pagamento',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.augdt != null || row.augdt != undefined)
            return "<center>" + row.augdt + "</center>";
          else
            return "";
        },
        isSelected: true,
        filter: true,
      },
      antecipado: {
        title: 'DT Antecipação',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.antecipado != null || row.antecipado != undefined)
            return "<center>" + row.antecipado + "</center>"
        },
        compareFunction: (direction: any, a: any, b: any) => {

          if (a === undefined && b === undefined) {
            return direction;
          }

          if (a === undefined && b != undefined) {
            return -1 * direction;
          }

          if (a != undefined && b === undefined) {
            return direction;
          }

          a = a.split("/");
          b = b.split("/");

          a = a[2] + "-" + a[1] + "-" + a[0];
          b = b[2] + "-" + b[1] + "-" + b[0];

          let first = typeof a === 'string' ? Date.parse(a) : a;
          let second = typeof b === 'string' ? Date.parse(b) : b;

          if (first < second)
            return -1 * direction;
          else
            return direction;
        },
        isSelected: true,
        filter: true,
      },
      nome_comprovante: {
        title: 'Comprovante',
        type: 'custom',
        renderComponent: DownloadcomprovanteComponent,
        isSelected: true,
        filter: true,
      },
      ocorrencia: {
        title: 'Ocorrência',
        type: 'custom',
        renderComponent: DetalheocorrenciaComponent,
        isSelected: true,
        filter: true,
      },
      id: {
        title: 'ID',
        isSelected: true,
        filter: true,
      },
    },
    pager: {
      display: true,
      perPage: 10,

    }
  };
  //#endregion

  public formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  constructor(private billstoPayprovider: NfsService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private cdRef: ChangeDetectorRef, ) {

    authService.verifyPermissionContasaPagar();

    this.settings = Object.assign({}, this.mySettingsTable)
    this.keys = Object.keys;

    this.filterForm = this.formBuilder.group({
      companyCode: new FormControl(null, Validators.required),
      dueDateFrom: new FormControl(this.payload.dueDateFrom, Validators.required),
      dueDateUntil: new FormControl(this.payload.dueDateUntil),
      status: new FormControl(null)
    });

    this.pageOptions = [10, 100, 250, 500];
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    this.payload.username = this.currentUser.username;

    let today = moment();
    let until = today.format("YYYY-MM-DD");
    let from = today.subtract(1, 'months').format("YYYY-MM-DD");

    this.payload.dueDateFrom = from;
    this.payload.dueDateUntil = until;

    this.filterForm.controls['dueDateFrom'].setValue(this.payload.dueDateFrom);
    this.filterForm.controls['dueDateUntil'].setValue(this.payload.dueDateUntil);

    if (this.currentUser.type == 'UL') {
      //UL = Usuario Loja           
      //Prctr = codigo do centro de lucro (id da loja) 
      this.payload.costCenter = this.currentUser.cost_center;
      this.optionsModel = this.payload.costCenter;
    }
    else {
      //UE = Usuario fornecedor    
      //Lifnr = codigo do forncedor 
      this.suppliers.map((item) => {
        this.payload.companyCode.push(item.id);
      });
      this.optionsModel = this.payload.companyCode.map((str) => str);

      if (this.suppliers[0])
        this.payload.id = this.suppliers[0].company;
    }

    this.getSuppliers();

    this.loadList();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  loadList() {
    this.loading = true;
    this.itemRows = [];

    this.billstoPayprovider.getNFs(this.payload).then((data) => {

      this.dataResponse = data['items'];

      this.dataResponse.forEach(element => {
        element.rows.forEach(rows => {

          //CNPJ
          if (rows.stcd1 != null || rows.stcd1 != undefined) {
            rows.stcd1 = rows.stcd1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
          }

          //ID
          rows.id = rows.bukrs + "/" + rows.belnr + "/" + rows.buzei + "/" + rows.gjahr;

          //R$ Devolução
          if (rows.dmbtr_crd != null || rows.dmbtr_crd != undefined) {
            rows.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(rows.dmbtr_crd);
          }
          else {
            rows.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
          }

          //IMPOSTOS (SOMA IRF)
          if (rows.impostos != null || rows.impostos != undefined) {
            rows.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(rows.impostos);
          }
          else {
            rows.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
          }

          //OCORRENCIA (cliente pediu para deixar definido a ocorrência sempre com o texto "DV")
          if (rows.log != null || rows.log != undefined) { rows.ocorrencia = "DV"; } else { rows.ocorrencia = ""; }

          this.itemRows.push(rows);
        });
      });

      this.totalPaid = this.formatter.format(data['total_paid']);
      this.totalOpen = this.formatter.format(data['total_open']);

      //Ordena por data de vencimento
      this.source = this.itemRows.sort(function (a, b) {
        if (Date.parse(a.faedt) > Date.parse(b.faedt))
          return 1;
        else
          return -1;
      });

      this.loading = false;

      //select qtd por página
      if (this.itemRows.length > 10) {
        this.showpselectpag = true;
      }
      else {
        this.showpselectpag = false;
        this.pagination = false;
        this.selectPage = false;
        this.mySettingsTable.pager.perPage = 10;
        this.settings = Object.assign({}, this.mySettingsTable);
      }

      //paginação genérica
      if (this.settings.pager.perPage < this.itemRows.length) {
        this.pagination = false;
      }
      else if ((this.settings.pager.perPage > this.itemRows.length) && (this.selectPage)) {
        this.pagination = true;
      }

    },
      error => {
        if (error.message == "Http failure response for (unknown url): 0 Unknown Error" && error.status != "404") {
          this.alertService.error("O período selecionado ultrapassou o limite. Favor selecionar um período menor.");
        }
        else {
          this.alertService.error(error.message);
        }

        this.loading = false;
      });

  }

  getSuppliers() {
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    let i = 0;
    for (i = 0; i < this.suppliers.length; i++) {
      this.myOptions.push({ id: this.suppliers[i].id, name: this.suppliers[i].cnpj + "-" + this.suppliers[i].name });
    }
  }

  updateForm()
  {
    this.payload.DownloadExcel = false;

    let dateFrom = moment(this.filterForm.get('dueDateFrom').value);
    let dateUntil = moment(this.filterForm.get('dueDateUntil').value);
    let dateDiff = dateUntil.diff(dateFrom, 'years', true);

    if (dateDiff > 1) {
      this.showDateError = true;
    } else {
      this.showDateError = false;
      let data = this.filterForm.getRawValue();

      this.payload.dueDateFrom = moment(data.dueDateFrom).format("YYYY-MM-DD");
      this.payload.dueDateUntil = moment(data.dueDateUntil).format("YYYY-MM-DD");

      if (data.status == "null")
        this.payload.status = null;
      else
        this.payload.status = data.status;

      if (this.currentUser.type == "UL") {

        if (data.companyCode instanceof Array) { this.payload.costCenter = data.companyCode[0]; }
        else { this.payload.costCenter = data.companyCode; }

        this.payload.companyCode = [];
      }
      else {
        this.payload.companyCode = [];

        data.companyCode.map((item) => {
          this.payload.companyCode.push(item);
        });

        this.payload.costCenter = null;
      }
    }
  }

  sendForm() {
    if (this.optionsModel.length == 0) {
      return false
    }
      this.updateForm();
      this.loadList();
  }

  public updateCols($event) {

    this.hideCols = '';
    let i = 0;
    for (const colI in this.settings.columns) {
      if (this.settings.columns[colI].isSelected) {
        i++;
      } else {
        i++;
        this.hideCols += `hd-cl-${i} `;
      }
    }
  }

  public ShowInfoAdc() {
    if (!this.viewShowInfoAdc)
      this.viewShowInfoAdc = true;
    else
      this.viewShowInfoAdc = false;
  }

  getHtmlForCellStatus(value: string) {

    //paginação genérica
    if (this.grdTags.source.filteredAndSorted) {
      if (this.settings.pager.perPage < this.grdTags.source.filteredAndSorted.length) {
        this.pagination = false;
      }
      else if (this.settings.pager.perPage > this.grdTags.source.filteredAndSorted.length) {
        this.pagination = true;
      }
    }

    if (value == "2") {
      return `<div title="Pago" class="legenda pago"><p>✔</p></div>`;
    }
    else {
      return `<div title="Aberto" class="legenda aberto"><p>!</p></div>`;
    }
  }

  downloadExcel() {
    this.loading = true;
    this.updateForm();
    this.payload.DownloadExcel = true;
    this.billstoPayprovider.getNFs(this.payload).then((data) => {

      this.loading = false;

      var arquivoExcelB64 = data["ExcelB64"];
      
      if(!arquivoExcelB64)
      {
        alert("Arquivo não disponibilizado pelo SAP!");
        return false;
      }

      let arquivo = arquivoExcelB64.toString();

      var stringBinary = window.atob(arquivo);
      var binarySize = stringBinary.length;
      var bytes = new Uint8Array(binarySize);
      for (var i = 0; i < binarySize; i++) {
        var ascii = stringBinary.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'text/csv' });
      const dataURL = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = "bills_to_pay_report.csv";

      //alteração para funcionar no IE(Funciona nos outros também)
      var event = new MouseEvent('click');
      link.dispatchEvent(event);

    }
      , (err) => {
        if (err.message == "Http failure response for (unknown url): 0 Unknown Error" && err.status != "404") {
          alert("O período selecionado ultrapassou o limite. Favor selecionar um período menor.");
        }
        else {
          alert(err.message);
        }

        this.loading = false;
      });

  }

  onChangePageRows(rowsperpage) {

    this.selectPage = true;
    this.pagination = false;
    this.mySettingsTable.pager.perPage = rowsperpage;
    this.settings = Object.assign({}, this.mySettingsTable);

    this.loadList();
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

}
