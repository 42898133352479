import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faEnvelope, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUser, faLock, faKey, faInfoCircle, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit {

  faEnvelope = faEnvelope;
  faQuestionCircle = faQuestionCircle;
  faCog = faCog;
  faSignOutAlt = faSignOutAlt;
  faLock = faLock;
  faKey = faKey;
  faInfoCircle = faInfoCircle;
  faUser = faUser;

  currentUser: any;
  suppliers: any;
  returnUrlLogin = '/login';

  permissions: any;
  hasContato = false;
  hasCadastro = false;
  hasContaPagar = false;
  hasSolicitarAntecipacao = false;
  hasAcompanharAntecipacao = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal) {

    //Validação de usuário       
    if (!localStorage.getItem('CurrentUser')) {
      this.router.navigate([this.returnUrlLogin]);
    }
    else {
      this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
      this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

      this.permissions = JSON.parse(localStorage.getItem('Permissions'));

      let i = 0;
      for (i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i] == "MAIL") {
          this.hasContato = true;
        }       
        else if (this.permissions[i] == "ADFN") {
          this.hasCadastro = true;
        }
        else if (this.permissions[i] == "PTIT") {
          this.hasContaPagar = true;
        }
        else if (this.permissions[i] == "SOAF") {
          this.hasSolicitarAntecipacao = true;
        }
        else if (this.permissions[i] == "ACAF") {
          this.hasAcompanharAntecipacao = true;
        }
      }
    }
  }

  ngOnInit() {

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate([this.returnUrlLogin]);
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showInfo(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-info' });
  }

}
