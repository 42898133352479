import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class NewsService {

  urlAPI = ENV.API_URL;

  constructor(private http: HttpClient) { }

  news(username:string){
    var controllerRoute = "api/news";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username})
        .map(data => {
            return data;
        });
  }

  newsread(id:string,username:string){
    var controllerRoute = "api/news/read";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, {id:id, username: username})
        .map(data => {
            return data;
        });
  }

  newsdismiss(id:string,username:string){
    var controllerRoute = "api/news/dismiss";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, {id:id, username: username})
        .map(data => {
            return data;
        });
  }

}
