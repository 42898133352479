import { Component, ViewChild, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormGroupDirective, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface, DropzoneMethodFunction } from 'ngx-dropzone-wrapper';
import { ContactUsService } from '../services/contactus.service';
import { AlertService } from '../services/alert.service';
import { Department, Subject, AutoResponse, SubSubject, Branch } from './interfaces';

import _ from 'lodash';
import { Router } from '@angular/router';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {
  contactForm: FormGroup;
  model: any = {};

  departmentOptions: Department[];
  allDepartmentOptions: Department[];

  autoResponses: AutoResponse[];
  allAutoResponses: AutoResponse[];
  autoResponse: any;

  subjectOptions: Subject[];
  allSubjectOptions: Subject[];

  subSubjectOptions: SubSubject[];
  allSubSubjectOptions: SubSubject[];

  branchOptions: Branch[];
  allBranchOptions: Branch[];

  currentUser: any;
  loading = false;

  hasSubSubject = false;
  hasBranch = false;
  hasAttachment = false;

  enableMessageField = false;

  submitted = false;

  attachmentsConfigSize: any;
  attachmentsConfigExtension: any;
  attachmentTotalSize: number = 0;
  attachmentsTotalSize: number = 0;
  textAttachment: any;
  fileAttachments: any = [];
  attachmentError = false;
  attachmentErrorRequired = false;
  sizeToRemove: number = 0;

  formData = {
    id: '',
    username: '',
    message: '',
    subSubject: '',
    branch: '',
    department: '',
    subject: '',
    attachments: '',
  };

  response: any[];
  permissions: any;

  //Configurações iniciais Dropzone
  config: DropzoneConfigInterface = {
    createImageThumbnails: true,
    clickable: true,
    addRemoveLinks: true,
    uploadMultiple: true,
    dictCancelUpload: "Cancelar",
    dictResponseError: "Servidor respondeu com código {{statusCode}}.",
    dictRemoveFile: "Remover",
    dictFileTooBig: "Arquivo é muito grande ({{filesize}}MB). Máximo de tamanho permitido: {{maxFilesize}}MB.",
    dictInvalidFileType: "Tipo de arquivo inválido.",
    parallelUploads: 1,
    autoProcessQueue: true,
  }

  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;
  @ViewChild('attachments') componentRef?: DropzoneComponent;

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  constructor(private contactUsService: ContactUsService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router) {

    authService.verifyPermissionContato();
    
    this.initializeContactFormControls();
  }

  initializeContactFormControls() {
    this.contactForm = this.formBuilder.group({
      department: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
      subSubject: new FormControl(null),
      branch: new FormControl(null),
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.loadOptions(this.currentUser.username);
  }

  get f() { return this.contactForm.controls; }

  loadOptions(username) {
    this.loading = true;
    let data = { username: username };
    this.contactUsService.getOptions(data).then((result) => {

      this.allDepartmentOptions = result['departments'];
      this.departmentOptions = this.allDepartmentOptions;

      this.allSubjectOptions = result['subjects'];

      this.allSubSubjectOptions = result['sub_subjects'];

      this.allBranchOptions = result['branches'];

      this.allAutoResponses = result['auto_responses'];

      this.attachmentTotalSize = +(result['attachments']['tamanhomax']);

      this.attachmentsConfigExtension = result['attachments']['listaextensao'].trim().replace(" ", " .").replace("", ".").split(" ");

      this.attachmentsConfigExtension = this.attachmentsConfigExtension.toString().toLowerCase();

      this.attachmentsConfigSize = result['attachments']['tamanhoum'];

      //Dropzone Info
      this.config.acceptedFiles = this.attachmentsConfigExtension;
      this.textAttachment = "Clique aqui para adicionar anexo: (Tamanho máximo total: " + this.attachmentTotalSize + " " + this.attachmentsConfigSize + "; Extensões Permitidas: " + this.config.acceptedFiles + ")";
      this.config.dictDefaultMessage = this.textAttachment;
      this.config.maxFilesize = this.attachmentTotalSize;

      this.loading = false;

    }, (err) => {
      this.loading = false;
      if (!err.error.message)
        //Quando não é possível acessar a api
        this.alertService.error("Erro de comunicação com o servidor.");
      else
        this.alertService.error(err.error.message);
    });
  }

  onChangeDepartment(department: Department) {
    this.loading = true;
    this.contactForm.controls['subject'].reset();
    this.subjectOptions = [];

    if (department != undefined || department != null) {
      let filteredSubjects = this.allSubjectOptions.filter((subject) => {
        return subject.department_id = department.id
      });

      this.subjectOptions = filteredSubjects;
    }

    this.loading = false;
  }

  onChangeSubject(subject: Subject, content) {
    if (subject != undefined || subject != null) {
      this.verifyAutoResponse(subject, content);
      this.verifySubSubject(subject);
      this.verifyBranch(subject);

      this.hasAttachment = subject.has_attachment;

      this.enableMessageField = true;

    } else {
      this.hasSubSubject = false;
      this.hasBranch = false;
      this.enableMessageField = false;
    }
  }

  onChangeSubSubject(subSubject: SubSubject) {
    this.verifyBranch(subSubject);
    this.hasAttachment = subSubject.has_attachment;
  }

  onChangeBranch(branch: Branch) {
    if (branch != undefined || branch != null)
      this.hasAttachment = branch.has_attachment;
  }

  verifyAutoResponse(subject: Subject, content) {
    if (subject.has_auto_response) {
      this.allAutoResponses.filter((response) => {
        return response.subject_id = subject.id
      });

      this.modalService.open(content, { ariaLabelledBy: 'modal-resposta-automatica' });
      this.clearForm();
    }
  }

  verifySubSubject(subject: Subject) {
    let formFieldSubSubject = this.contactForm.get('subSubject');

    formFieldSubSubject.clearValidators();
    formFieldSubSubject.setValue(null);

    this.subSubjectOptions = null;

    if (subject.has_sub_subject) {
      let subSubjects = this.allSubSubjectOptions;

      let filteredSubSubjects = subSubjects.filter((data) => {
        return data.subject_id = subject.id
      });

      this.subSubjectOptions = filteredSubSubjects;
      this.hasSubSubject = true;
      formFieldSubSubject.reset();
      formFieldSubSubject.enable();
      formFieldSubSubject.setValidators(Validators.required);

    } else {
      this.hasSubSubject = false;
    }
  }

  verifyBranch(subject: any) {
    let formFieldBranch = this.contactForm.get('branch');

    formFieldBranch.clearValidators();
    formFieldBranch.setValue(null);

    this.branchOptions = null;

    if (subject != undefined || subject != null) {
      if (subject.has_branch) {

        let branches = this.allBranchOptions;
        this.branchOptions = _.sortBy(branches[subject.id], [(o) => { return o.cnpj; }]);

        this.branchOptions = _.uniqBy(this.branchOptions, 'id');
        formFieldBranch.reset();
        formFieldBranch.enable();
        formFieldBranch.setValidators(Validators.required);

        this.hasBranch = true;
      } else {
        this.hasBranch = false;
      }
    }
  }

   clearForm() {
    this.contactForm.get('department').clearValidators();
    this.contactForm.get('subject').clearValidators();
    this.contactForm.get('subSubject').clearValidators();
    this.contactForm.get('branch').clearValidators();
    this.contactForm.get('message').clearValidators();

    this.submitted = false;

    //Limpa anexos
    this.fileAttachments = [];
    this.attachmentsTotalSize = 0;

    this.componentRef.directiveRef.reset();

    this.departmentOptions = this.allDepartmentOptions;
    this.subjectOptions = null;
    this.subSubjectOptions = null;
    this.branchOptions = null;

    this.enableMessageField = false;
    this.hasBranch = false;
    this.hasSubSubject = false;
    this.attachmentErrorRequired = false;

    this.contactForm.reset();
    this.contactForm.markAsUntouched();
  }

  sendForm() {
    this.submitted = true;

    if (this.contactForm.controls['department'].value == null) {
      this.contactForm.controls['department'].setErrors({ 'required': true });
    }

    if (this.contactForm.controls['subject'].value == null) {
      this.contactForm.controls['subject'].setErrors({ 'required': true });
    }

    if (this.contactForm.controls['subSubject'].value == null && this.hasSubSubject) {
      this.contactForm.controls['subSubject'].setErrors({ 'required': true });
    }

    if (this.contactForm.controls['branch'].value == null && this.hasBranch) {
      this.contactForm.controls['branch'].setErrors({ 'required': true });
    }

    if (this.contactForm.controls['message'].value == null) {
      this.contactForm.controls['message'].setErrors({ 'required': true });
    }

    if (this.hasAttachment && !(this.fileAttachments.length > 0)) {
      this.attachmentErrorRequired = true;
    }

    if (this.contactForm.invalid || this.attachmentError || this.attachmentErrorRequired) {
      return;
    }

    if (this.hasAttachment && this.fileAttachments.length == 0) {
      this.attachmentErrorRequired = true;
    } else {
      this.attachmentErrorRequired = false;
      this.formData.id = this.currentUser.supplier_id;
      this.formData.username = this.currentUser.username;
      this.formData.message = this.contactForm.get('message').value;
      this.formData.department = this.contactForm.get('department').value.label;
      this.formData.subject = this.contactForm.get('subject').value.label;
      this.formData.attachments = this.fileAttachments;

      if (this.contactForm.get('subSubject').value) {
        this.formData.subSubject = this.contactForm.get('subSubject').value.label;
      }
      else
      {
        this.formData.subSubject = '';
      }

      if (this.contactForm.get('branch').value) {
        this.formData.branch = this.contactForm.get('branch').value.name;
      }
      else
      {
        this.formData.branch = '';
      }

      this.loading = true;

      this.contactUsService.sendContact(this.formData).then((result: any) => {
        this.alertService.success(result.sendStatus[0].msg);
        this.loading = false;
        this.clearForm();
      }).catch((error) => {
        this.loading = false;
        this.alertService.error("Ocorreu um erro ao tentar enviar email.");
      });
    }

  }

  public onFileRemoved(args: any): void {
    let mbSize: any;
    if (args.size != undefined || args.size != null)
      mbSize = this.formatFileSize(args.size);
    else
      mbSize = this.formatFileSize(args[0].size);

    this.attachmentsTotalSize -= +mbSize;

    this.fileAttachments = this.fileAttachments.filter(item => item.name != args.name);
    if (this.attachmentsTotalSize < this.attachmentTotalSize)
      this.attachmentError = false;
    else
      this.attachmentError = true;
  }

  public onUploadError(args: any): void {
    this.alertService.error("Anexo maior que o limite ou com extensão não permitida.");
    let dz = this.componentRef.directiveRef.dropzone();

    let mbSize = this.formatFileSize(args[0].upload.total);
    this.attachmentsTotalSize += +mbSize;
    
    dz.removeFile(args[0]);
  }

  public onUploadSuccess(args: any): void {
    let mbSize = this.formatFileSize(args[0].upload.total);
    this.attachmentsTotalSize += +mbSize;

    this.fileAttachments.push({
      name: args[0].name.split('.')[0],
      type: args[0].name.split('.').pop(),
      base64: args[1].files["file[0]"].split(',').pop(),
    });
   

    if (this.attachmentsTotalSize < this.attachmentTotalSize) {
      this.attachmentError = false;
    }
    else {
      let dz = this.componentRef.directiveRef.dropzone();
      dz.removeFile(args[0]);
      this.attachmentError = true;
    }

    if (this.fileAttachments != null)
      this.attachmentErrorRequired = false;
    else
      this.attachmentErrorRequired = true;
  }

  formatFileSize(bytes) {
    if (+bytes == 0) return 0;
    return parseFloat((+bytes / (1024 * 1024)).toFixed(2));
  }

}
