import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faFileExcel, faSmile } from '@fortawesome/free-solid-svg-icons';
import { AnticipationService } from '../services/anticipation.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { ServerDataSource } from 'ng2-smart-table';
import { DetalheirfComponent } from '../modaldetalhes/detalheirf/detalheirf.component';
import { DetalhevalordevolucaoComponent } from '../modaldetalhes/detalhevalordevolucao/detalhevalordevolucao.component';
import _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-acompanharantecipacao',
  templateUrl: './acompanharantecipacao.component.html',
  styleUrls: ['./acompanharantecipacao.component.css']
})
export class AcompanharantecipacaoComponent implements OnInit {

  @ViewChild('grdTags') grdTags;

  //#region Declaração ícones
  faFileExcel = faFileExcel;
  loading = false;
  currentUser: any;
  showpselectpag = false;
  //#endregion

  //#region Características multiSelect
  // Default selection
  optionsModel: number[];
  myOptions: IMultiSelectOption[] = [];

  // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Todos Selecionados',
    uncheckAll: 'Desmarque todos',
    checked: 'item selecionado',
    checkedPlural: 'itens selecionados',
    searchPlaceholder: '',
    searchEmptyResult: 'Nada encontrado...',
    searchNoRenderText: 'Digite na caixa de pesquisa para ver os resultados ...',
    defaultTitle: 'Selecione o CNPJ',
    allSelected: 'Todos selecionados',
  };
  //#endregion

  //#region Tabela
  settings;
  mySettingsTable = {
    actions: false,
    columns: {
      'status': {
        type: 'html',
        title: 'Status',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          return this.getHtmlForCellStatus(row.status)
        },
        isSelected: true,
      },
      operacao: {
        title: 'Operação',
        isSelected: true,
        filter: true
      },
      stcd1: {
        title: 'CNPJ',
        isSelected: true,
        filter: true
      },
      name1: {
        title: 'Razão Social',
        isSelected: true,
        filter: true
      },
      xblnr: {
        title: 'Nota fiscal',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.xblnr + "</center>" },
        isSelected: true,
        filter: true
      },
      bldat: {
        title: 'DT NF',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.bldat + "</center>" },
        isSelected: true,
        filter: true
      },
      budat: {
        title: 'DT Recebimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.budat + "</center>" },
        isSelected: true,
        filter: true
      },
      dmbtr_format: {
        title: 'R$ Bruto',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.dmbtr_format != null || row.dmbtr_format != undefined)
            return "<div class='tright'> R$ " + row.dmbtr_format + "</div>";
          else
            return this.formatter.format(0);
        },
        isSelected: true,
        filter: true
      },
      'impostos': {
        title: 'IRF',
        type: 'custom',
        renderComponent: DetalheirfComponent,
        filter: true,
        isSelected: true
      },
      'dmbtr_crd': {
        title: 'R$ Devolução',
        type: 'custom',
        renderComponent: DetalhevalordevolucaoComponent,
        filter: true,
        isSelected: true
      },
      faedt: {
        title: 'DT Vencimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.faedt + "</center>" },
        isSelected: true,
        filter: true
      },
      due_date: {
        title: 'DT Solicitação',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.due_date + "</center>" },
        compareFunction: (direction: any, a: any, b: any) => {
          let first = typeof a === 'string' ? Date.parse(a) : a;
          let second = typeof b === 'string' ? Date.parse(b) : b;

          if (first < second)
            return -1 * direction;
          else
            return direction;
        },
        isSelected: true,
        filter: true
      },
      id: {
        title: 'ID',
        isSelected: true,
        filter: true
      },
    },
    pager: {
      display: true,
      perPage: 10
    }
  };

  viewShowInfoAdc = false;
  hideCols: any;
  keys: any;
  //#endregion

  //#region Variáveis
  payload = {
    companyCode: [],
    id: null,
    dateFrom: null,
    dateUntil: null,
    status: []
  };

  suppliers: any;

  dataResponse: any = [];
  datatickets: any = [];
  datarows: any = [];
  formData: any = [];
  showDateError = false;

  filterForm: FormGroup;

  totalPaid: any;
  totalOpen: any;

  rejectedTotal = "0,00";
  pendingTotal = "0,00";
  approvedTotal = "0,00";
  allRegs: any;

  source: any = [];
  permissions: any;
  array = [];
  pagination: boolean = false;
  selectPage: boolean = false;

  //#endregion

  public formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  pageOptions: any[];

  constructor(
    private antiService: AnticipationService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef, ) {
    authService.verifyPermissionAcompanharAntecipacao();

    this.settings = Object.assign({}, this.mySettingsTable)
    this.keys = Object.keys;

    this.filterForm = this.formBuilder.group({
      suppliers: new FormControl(null, Validators.required),
      dateFrom: new FormControl(this.payload.dateFrom, Validators.required),
      dateUntil: new FormControl(this.payload.dateUntil),
      status: new FormControl(null)
    });

    this.pageOptions = [10, 100, 250, 500];
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    let today = moment();
    let from = today.format("YYYY-MM-DD");
    let until = today.add(30, 'days').format("YYYY-MM-DD");

    this.payload.dateFrom = from;
    this.payload.dateUntil = until;

    this.filterForm.controls['dateFrom'].setValue(this.payload.dateFrom);
    this.filterForm.controls['dateUntil'].setValue(this.payload.dateUntil);

    this.getSuppliers();
    this.loadList();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getSuppliers() {
    this.loading = true;
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    this.suppliers.map((item) => {
      this.payload.companyCode.push(item.id);
    });

    this.optionsModel = this.payload.companyCode.map((str) => str);
    this.payload.id = supplier.company;

    let i = 0;
    for (i = 0; i < this.suppliers.length; i++) {
      this.myOptions.push({ id: this.suppliers[i].id, name: this.suppliers[i].cnpj + "-" + this.suppliers[i].name });
    }

    this.loading = false;
  }

  loadList() {
    this.loading = true;

    this.antiService.getFollowUps(this.payload).then((result) => {
      this.loading = true;

      this.dataResponse = result['items'];

      this.pendingTotal = this.formatter.format(result['total_pending']);
      this.approvedTotal = this.formatter.format(result['total_approved']);
      this.rejectedTotal = this.formatter.format(result['total_rejected']);

      this.array = [];

      this.dataResponse.forEach(element => {
        element.rows.forEach(e => {
          e.due_date = element.due_date;

          //CNPJ
          if (e.stcd1 != null || e.stcd1 != undefined) {
            e.stcd1 = e.stcd1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
          }

          //ID
          e.id = e.bukrs + "/" + e.belnr + "/" + e.buzei + "/" + e.gjahr;

          //R$ Devolução
          if (e.dmbtr_crd != null || e.dmbtr_crd != undefined) {
            e.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(e.dmbtr_crd);
          }
          else {
            e.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
          }

          //IMPOSTOS (SOMA IRF)
          if (e.impostos != null || e.impostos != undefined) {
            e.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(e.impostos);
          }
          else {
            e.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
          }
          this.array.push(e);
        });
      });

      //Ordena por data de solicitação
      this.array = this.transform(this.array, "due_date", true);
      this.source = this.array;

      //select qtd por página
      if (this.array.length > 10) {
        this.showpselectpag = true;
      }
      else {
        this.showpselectpag = false;
        this.pagination = false;
        this.selectPage = false;
        this.mySettingsTable.pager.perPage = 10;
        this.settings = Object.assign({}, this.mySettingsTable);
      }

      //paginação genérica
      if (this.settings.pager.perPage < this.array.length) {
        this.pagination = false;
      }
      else if ((this.settings.pager.perPage > this.array.length) && (this.selectPage)) {
        this.pagination = true;
      }

      this.loading = false;

    }, (err) => {
      this.loading = true;
      this.alertService.error("Erro ao buscar dados.");
      this.loading = false;
    });
  }

  sendForm() {
    if (this.optionsModel.length == 0) {
      return false
    }
    
    this.loading = true;
    this.showDateError = false;

    let dateFrom = moment(this.filterForm.get('dateFrom').value);
    let dateUntil = moment(this.filterForm.get('dateUntil').value);
    let dateDiff = dateUntil.diff(dateFrom, 'years', true);

    if (dateDiff > 1) {
      this.showDateError = true;
      this.source = [];
      this.loading = false;
    }
    else {
      this.loading = true;
      this.showDateError = false;
      let data = this.filterForm.getRawValue();

      if (data.status == null || data.status == "null")
        this.payload.status = ['P', 'A', 'R'];
      else if (data.status == 1)
        this.payload.status = ['P'];
      else if (data.status == 2)
        this.payload.status = ['A'];
      else if (data.status == 3)
        this.payload.status = ['R'];

      this.payload.dateFrom = moment(data.dateFrom).format("YYYY-MM-DD");
      this.payload.dateUntil = moment(data.dateUntil).format("YYYY-MM-DD");
      this.payload.companyCode = [];
      this.payload.companyCode = data.suppliers;

      this.loadList();
    }
  }

  getHtmlForCellStatus(value: string) {

    //paginação genérica
    if (this.grdTags.source.filteredAndSorted) {
      if (this.settings.pager.perPage < this.grdTags.source.filteredAndSorted.length) {
        this.pagination = false;
      }
      else if (this.settings.pager.perPage > this.grdTags.source.filteredAndSorted.length) {
        this.pagination = true;
      }
    }

    if (value == "A") {
      return '<div  title="Aprovado" class="legenda pago"><p>✔</p></div>';
    }
    else if (value == "R") {
      return '<div  title="Rejeitado" class="legenda nao-apto"><p>X</p></div>';
    }
    else {
      return '<div  title="Pendente" class="legenda aberto"><p>!</p></div>';
    }
  }

  public updateCols($event) {

    this.hideCols = '';
    let i = 0;
    for (const colI in this.settings.columns) {
      if (this.settings.columns[colI].isSelected) {
        i++;
      } else {
        i++;
        this.hideCols += `hd-cl-${i} `;
      }
    }
  }

  ShowInfoAdc() {
    if (!this.viewShowInfoAdc)
      this.viewShowInfoAdc = true;
    else
      this.viewShowInfoAdc = false;
  }

  downloadExcel() {
    this.loading = true;

    this.antiService.getReportfollowup(this.payload).then((result) => {

      this.loading = false;

      let arquivo = result.toString();

      var stringBinary = window.atob(arquivo);
      var binarySize = stringBinary.length;
      var bytes = new Uint8Array(binarySize);
      for (var i = 0; i < binarySize; i++) {
        var ascii = stringBinary.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'application/xlsx' });
      const dataURL = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = "anticipation_followup_report.xlsx";

      //alteração para funcionar no IE(Funciona nos outros também)
      var event = new MouseEvent('click');
      link.dispatchEvent(event);

    }
      , (err) => {
        alert("Erro ao baixar o excel.");
      });

  }

  transform(array, orderBy, asc = true) {

    if (!orderBy || orderBy.trim() == "") {
      return array;
    }

    //ascending
    if (asc) {
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item1[orderBy], item2[orderBy]);
      });
    }
    else {
      //not asc
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item2[orderBy], item1[orderBy]);
      });
    }

  }

  orderByComparator(a: any, b: any): number {

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      //Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    }
    else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }

  onChangePageRows(rowsperpage) {
    this.selectPage = true;
    this.pagination = false;
    this.mySettingsTable.pager.perPage = rowsperpage;
    this.settings = Object.assign({}, this.mySettingsTable);

    this.loadList();
  }

}
