import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class ImageService {
    urlAPI = ENV.API_URL;
    header = new HttpHeaders()

    constructor(public http: HttpClient) {
        this.header.append('Content-type', 'application/json')
        this.header.append('Access-Control-Allow-Origin', '*')
    }
    
    loadImage() {
        return new Promise((resolve, reject) => {
            this.http.post(this.urlAPI + '/api/image', { headers: this.header }).subscribe(
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }
}
