import { Component, Input, Output, EventEmitter, ViewChild, OnInit, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { faFileExcel, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ENV } from '../config/config';

import { AuthenticationService } from '../services/auth.service';
import { AnticipationService } from '../services/anticipation.service';
import { AlertService } from '../services/alert.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';

import { DetalheirfComponent } from '../modaldetalhes/detalheirf/detalheirf.component';
import { DetalhevalordevolucaoComponent } from '../modaldetalhes/detalhevalordevolucao/detalhevalordevolucao.component';
import { MessageblockComponent } from '../solicitarantecipacao/messageblock/messageblock.component';

import _ from 'lodash';
import { equal } from 'assert';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-solicitarantecipacao',
  templateUrl: './solicitarantecipacao.component.html',
  styleUrls: ['./solicitarantecipacao.component.css']
})
export class SolicitarantecipacaoComponent implements OnInit {
  @ViewChild('grdTags') grdTags;
  @ViewChild(MessageblockComponent) errorModal: MessageblockComponent;

  input: string;
  block: boolean = false;

  //#region Declaração ícones
  faFileExcel = faFileExcel;
  faHandHoldingUsd = faHandHoldingUsd;
  faQuestionCircle = faQuestionCircle;
  //#endregion

  loading = false;

  currentUser: any;
  suppliers: any;
  hideCols: any;
  keys: any;
  viewShowInfoAdc = false;

  payload = {
    companyCode: [],
    id: null
  };

  dataResponse: any = [];
  datatickets: any = [];
  datarows: any = [];
  dataIrf: any = [];

  formData: any = [];

  totalAvailable = "0,00";
  totalNotAvailable = "0,00";

  totalAntecipar = 0;
  totalMontanteTitulos = 0;

  resumo = {
    valor_bruto: 0,
    irf: 0,
    devolucoes: 0,
    desconto_comerciais: 0,
    taxas: [],
    juros_antecipacao: 0,
    data_antecipacao: '',
    liquido: 0,
    taxaAvg: 0.00,
    selectedItensToAntecipate: []
  }

  evento = {
    data: [],
    isSelected: false,
  }

  selected: any;

  source: LocalDataSource;
  filterForm: FormGroup;
  permissions: any;
  message: string;

  listchecknow: any = [];
  listcheckall: number[] = [];
  rowsscreen: any = [];

  public formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  //#region Características multiSelect

  // Default selection
  optionsModel: number[];
  myOptions: IMultiSelectOption[] = [];

  // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: true
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Todos Selecionados',
    uncheckAll: 'Desmarque todos',
    checked: 'item selecionado',
    checkedPlural: 'itens selecionados',
    searchPlaceholder: '',
    searchEmptyResult: 'Nada encontrado...',
    searchNoRenderText: 'Digite na caixa de pesquisa para ver os resultados ...',
    defaultTitle: 'Selecione o CNPJ',
    allSelected: 'Todos selecionados',
  };
  //#endregion

  //#region Tabela
  settings;
  mySettingsTable = {
    selectMode: 'multi',
    actions: false,
    columns: {
      'statusTable': {
        type: 'html',
        title: 'Status',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          return this.getHtmlForCellStatus(row)
        },
        isSelectedColumn: true
      },
      operacao: {
        title: 'Operação',
        isSelectedColumn: true,
        filter: true
      },
      stcd1: {
        title: 'CNPJ',
        isSelectedColumn: true,
        filter: true
      },
      name1: {
        title: 'Razão Social',
        isSelectedColumn: true,
        filter: true
      },
      xblnr: {
        title: 'Nota Fiscal',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          return "<center>" + row.xblnr + "</center>";
        },
        isSelectedColumn: true,
        filter: true,
      },
      bldat: {
        title: 'DT NF',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.bldat + "</center>" },
        isSelectedColumn: true,
        filter: true
      },
      budat: {
        title: 'DT Recebimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.budat + "</center>" },
        isSelectedColumn: true,
        filter: true
      },
      dmbtr_format: {
        title: 'R$ Bruto',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.dmbtr_format != null || row.dmbtr_format != undefined)
            return "<div class='tright'> R$ " + row.dmbtr_format + "</div>";
          else
            return this.formatter.format(0);
        },
        isSelectedColumn: true,
        filter: true
      },
      'impostos': {
        title: 'IRF',
        type: 'custom',
        renderComponent: DetalheirfComponent,
        filter: true,
        isSelected: true,
        isSelectedColumn: true
      },
      dmbtr_crd: {
        title: 'R$ Devolução',
        type: 'custom',
        renderComponent: DetalhevalordevolucaoComponent,
        filter: true,
        isSelected: true,
        isSelectedColumn: true
      },
      faedt: {
        title: 'DT Vencimento',
        type: 'html',
        valuePrepareFunction: (cell, row) => { return "<center>" + row.faedt + "</center>" },
        isSelectedColumn: true,
        filter: true,
        compareFunction: (direction: any, a: any, b: any) => {
          let first = typeof a === 'string' ? Date.parse(a) : a;
          let second = typeof b === 'string' ? Date.parse(b) : b;

          if (first < second)
            return -1 * direction;
          else
            return direction;
        }
      },
      id: {
        title: 'ID',
        isSelectedColumn: true,
        filter: true
      },
    },
    pager: {
      display: false,
    },
    rowClassFunction: (row) => {
      if (row.data.status === 'AA') {
        return 'apt-status';
      } else {
        return 'inapt-status';
      }
    },
  };
  //#endregion

  //variáveis para alterar campos em produção
  currentURL = '';
  showTaxa: boolean = false;
  showJuros: boolean = false;
  showLiquidoAntecipar: boolean = false;
  colunaAntecipacao: string = '';

  constructor(private anticipationService: AnticipationService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    authService.verifyPermissionSolicitarAntecipacao();

    this.filterForm = this.formBuilder.group({
      suppliers: new FormControl(null, Validators.required),
    });
    this.settings = Object.assign({}, this.mySettingsTable)
    this.keys = Object.keys;

    this.currentURL = window.location.href;

    //Ocultar o campo Taxa
    //Ocultar o campo Líquido a Antecipar
    //Ocultar campo Juros
    //Mudar o nome da coluna de Data de Antecipação para Data de Solicitação
    if (this.currentURL.includes(ENV.PROD_URL)) {
      this.showTaxa = false;
      this.showLiquidoAntecipar = false;
      this.showJuros = false;
      this.colunaAntecipacao = 'Data de Solicitação';
    } else {
      this.showTaxa = true;
      this.showLiquidoAntecipar = true;
      this.showJuros = true;
      this.colunaAntecipacao = 'Data de Antecipação';
    }
  }

  ngOnInit() {
    this.loading = true;
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));

    this.getSuppliers();

    //Bloqueado para fazer antecipações
    this.anticipationService.getCompanies(this.currentUser.supplier_id).subscribe(data => {

      for (let index = 0; index < data.companies.length; index++) {
        const element = data.companies[index];

        if (element.dados_empresariais.hasOwnProperty('zzmotbloq')) {
          this.block = true;
          this.message = element.dados_empresariais.zzmotbloq;
          localStorage.setItem("Message", this.message);
          const modalRef = this.modalService.open(MessageblockComponent, { ariaLabelledBy: 'modal-confirm', size: 'sm', backdrop: 'static', });
          this.loading = false;
        }
      }

      if (!this.block) {
        this.loadList();

        this.loading = true;
      }

    }, (err) => {
      this.loading = true;

      this.alertService.error("Erro ao buscar dados.");
      this.loading = false;
    });

  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getSuppliers() {
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    this.suppliers.map((item) => {
      this.payload.companyCode.push(item.id);
    });

    this.optionsModel = this.payload.companyCode.map((str) => str);
    this.payload.id = supplier.company;

    let i = 0;
    for (i = 0; i < this.suppliers.length; i++) {
      this.myOptions.push({ id: this.suppliers[i].id, name: this.suppliers[i].cnpj + "-" + this.suppliers[i].name });
    }
  }

  sendForm() {
    if (this.optionsModel.length == 0) {
      return false
    }

    this.formData = this.filterForm.getRawValue();
    this.payload.companyCode = this.formData.suppliers;

    this.loadList();
    this.loading = true;
  }

  loadList() {
    this.listcheckall = [];

    this.anticipationService.getAvailable(this.payload).then((result) => {

      this.dataResponse = [];
      this.datarows = [];
      this.datatickets = [];
      this.totalAntecipar = 0;
      this.totalMontanteTitulos = 0;

      this.loading = true;

      this.dataResponse = result['items'];
      this.totalAvailable = result['total_available'];
      this.totalNotAvailable = result['total_not_available'];

      let Available = parseFloat(this.totalAvailable.replace(".", "").replace(",", "."));
      let notAvailable = parseFloat(this.totalNotAvailable.replace(".", "").replace(",", "."));
      this.totalMontanteTitulos = Available + notAvailable;

      let idx = 0;

      for (let index = 0; index < this.dataResponse.length; index++) {
        const element = this.dataResponse[index];

        for (let index = 0; index < element.rows.length; index++) {
          const rows = element.rows[index];
          this.datarows.push(rows);

          for (let index = 0; index < rows.tickets.length; index++) {
            const ticket = rows.tickets[index];

            ticket.ControleIndice = idx++;

            if (ticket.status == "AA") {
              ticket.statusTable = `<div class="legenda pago" title= "` + ticket.status_descr + `" ><p>✔</p></div>`;
              this.totalAntecipar += 1;
            } else {
              ticket.statusTable = `<div class="legenda nao-apto" title= "` + ticket.status_descr + `"><p>-</p></div>`;
            }

            // Soma IRF
            this.dataIrf = 0;
            if (ticket.irf) {
              ticket.impostos = 0;
              this.dataIrf += _.sumBy(ticket.irf, (o) => {
                return parseFloat(o.montante);
              });
            }

            ticket.stcd1 = ticket.stcd1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            ticket.id = ticket.bukrs + "/" + ticket.belnr + "/" + ticket.buzei + "/" + ticket.gjahr;

            if (ticket.dmbtr_crd != null || ticket.dmbtr_crd != undefined) {
              ticket.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(ticket.dmbtr_crd);
            } else {
              ticket.dmbtr_crd = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
            }

            ticket.impostos = this.dataIrf;
            if (ticket.impostos != null || ticket.impostos != undefined) {
              ticket.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(ticket.impostos);
            } else {
              ticket.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
            }

            this.datatickets.push(ticket);
          }
        }
      }

      //Ordena por data de vencimento
      this.source = new LocalDataSource(

        this.datatickets.sort(function (a, b) {
          if (Date.parse(a.faedt) > Date.parse(b.faedt))
            return 1;
          else
            return -1;
        })

      );

      this.loading = false;

    }, (err) => {
      this.loading = true;

      //Bloqueado quando a conta está aberta no SAP
      if (err.status == 400) {
        this.message = "Conta em processamento interno. Tente novamente em alguns minutos.";
        localStorage.setItem("Message", this.message);
        const modalRef = this.modalService.open(MessageblockComponent, { ariaLabelledBy: 'modal-confirm', size: 'sm', backdrop: 'static', });
      }
      else {
        this.alertService.error("Erro ao buscar dados.");
        this.loading = false;

      }
    });
  }

  rowSelect(event) {
    if (this.listcheckall.indexOf(event.data.ControleIndice) < 0) {
      this.returnCheckItem(event);
      this.listcheckall.push(event.data.ControleIndice);
    } else {
      console.log('Já selecionado');
    }
  }

  rowUnSelect(event) {
    let idx = this.listcheckall.indexOf(event.data.ControleIndice);
    if (idx >= 0) {
      this.returnCheckItem(event);
      this.listcheckall.splice(idx, 1);
    } else {
      console.log('Já não selecionado');
    }
  }

  allVisibleRowsSelect(gridRows: any) {
    if (!gridRows)
      return;

    for (let index = 0; index < gridRows.length; index++) {
      const row = gridRows[index];
      if (!row.data || row.data.status != 'AA')
        continue;

      let idxSelected = this.listcheckall.indexOf(row.data.ControleIndice);
      if (idxSelected < 0) {
        this.listcheckall.push(row.data.ControleIndice);
        this.resumo.selectedItensToAntecipate.push(row.data);
        this.doCalculate(row.data, true);
      }
    }
  }

  allVisibleRowsUnSelect(gridRows: any) {
    if (!gridRows)
      return;

    for (let index = 0; index < gridRows.length; index++) {
      const row = gridRows[index];
      if (!row.data || row.data.status != 'AA')
        continue;

      let idxSelected = this.listcheckall.indexOf(row.data.ControleIndice);
      if (idxSelected >= 0) {
        this.listcheckall.splice(idxSelected, 1);
        this.resumo.selectedItensToAntecipate.splice(row.data, 1);
        this.doCalculate(row.data, false);
      }
    }
  }

  onRowSelect(event) {
    if (event.data && event.data.status != 'AA')
      return;

    if (event.data) {

      if (event.isSelected) {
        this.rowSelect(event);
        this.doCalculate(event.data, true);
      } else {
        this.rowUnSelect(event);
        this.doCalculate(event.data, false);
      }

      return;
    }

    let isAllSelected = this.grdTags.isAllSelected;
    let gridVisibleRows = this.grdTags.grid.dataSet.rows;
    if (isAllSelected)
      this.allVisibleRowsSelect(gridVisibleRows);
    else
      this.allVisibleRowsUnSelect(gridVisibleRows);

    console.log('Selecionados: ');
    console.log(this.listcheckall);
    return;
  }

  returnCheckItem(event) {
    this.selected = event.data;

    if (this.grdTags.grid.dataSet.selectedRow != null) {
      if (this.grdTags.grid.dataSet.selectedRow.isSelected)
        this.resumo.selectedItensToAntecipate.push(this.selected);
      else
        this.resumo.selectedItensToAntecipate = this.resumo.selectedItensToAntecipate.filter(item => item !== this.selected);
    }
  }

  doCalculate(data: any, isSelecting: boolean) {
    //Registro aptos a serem contabilizado
    if (isSelecting) {
      if (data.impostos) {
        if (data.impostos != "0,00") {
          this.resumo.irf += parseFloat(data.impostos.replace(".", "").replace(",", "."));
        }
      }

      if (data.docscrd) {
        this.resumo.desconto_comerciais += _.sumBy(data.docscrd, (o) => {
          return parseFloat(o.dmbtr);
        });
      }

      this.resumo.valor_bruto += data.dmbtr;
      this.resumo.liquido += parseFloat(data.vlrliqant);
      this.resumo.juros_antecipacao += (data.dmbtr - parseFloat(data.vlrliqant));

      this.resumo.taxas.push({ id: data.xblnr, tax: parseFloat(data.txantm) });
      this.resumo.taxaAvg = _.meanBy(this.resumo.taxas, 'tax');

      this.resumo.data_antecipacao = data.dtantfin;
    }
    //Registro aptos a serem descontado
    else if (!isSelecting) {

      if (data.impostos) {
        if (data.impostos != "0,00") {
          this.resumo.irf -= parseFloat(data.impostos.replace(".", "").replace(",", "."));
        }
      }

      if (data.docscrd) {
        this.resumo.desconto_comerciais -= _.sumBy(data.docscrd, (o) => {
          return parseFloat(o.dmbtr);
        });
      }

      this.resumo.valor_bruto -= data.dmbtr;
      this.resumo.liquido -= parseFloat(data.vlrliqant);
      this.resumo.juros_antecipacao -= (data.dmbtr - parseFloat(data.vlrliqant));

      _.remove(this.resumo.taxas, (tax) => (tax.id == data.xblnr));
      let taxaAvg = _.meanBy(this.resumo.taxas, 'tax');

      if (isNaN(taxaAvg)) {
        taxaAvg = 0.00;
      }

      this.resumo.taxaAvg = taxaAvg;

      if (data.length <= 0) {
        this.resumo.data_antecipacao = '';
      }

      if (this.resumo.liquido < 0) {
        this.resumo.liquido = 0;
      }

      if (this.resumo.valor_bruto < 0) {
        this.resumo.valor_bruto = 0;
      }

      if (this.resumo.juros_antecipacao < 0) {
        this.resumo.juros_antecipacao = 0;
      }
    }
  }

  cleanResumo() {
    this.resumo.irf = 0;
    this.resumo.desconto_comerciais = 0;
    this.resumo.valor_bruto = 0;
    this.resumo.juros_antecipacao = 0;
    this.resumo.taxaAvg = 0;
    this.resumo.data_antecipacao = '';
    this.resumo.liquido = 0;
    this.resumo.valor_bruto = 0;
    this.resumo.selectedItensToAntecipate = [];
  }

  public updateCols($event) {

    this.hideCols = '';
    let i = 1;
    for (const colI in this.settings.columns) {
      if (this.settings.columns[colI].isSelectedColumn) {
        i++;
      } else {
        i++;
        this.hideCols += `hd-cl-${i} `;
      }
    }
  }

  ShowInfoAdc() {
    if (!this.viewShowInfoAdc)
      this.viewShowInfoAdc = true;
    else
      this.viewShowInfoAdc = false;
  }

  getCheckbox(row: any) {
    if (row.status != "AA") {
      this.input = ``;
    }
    else {
      this.input = `<input type="checkbox"></input>`;
    }
    return this.input;
  }

  getHtmlForCellStatus(gridRow: any) {
    if (this.listcheckall.length == 0)
      return gridRow.statusTable;

    let rowIndex = this.listcheckall.indexOf(gridRow.ControleIndice);

    if (rowIndex >= 0) {
      //a partir dos registros mostrados na tela, é verificado quais estão selecionados para armazenar na lista.
      for (let index = 0; index < this.grdTags.grid.dataSet.rows.length; index++) {
        const row = this.grdTags.grid.dataSet.rows[index];
        if (row.data.ControleIndice == gridRow.ControleIndice)
          row.isSelected = true;
      }
    }

    var rowSelectFalse = this.grdTags.grid.dataSet.rows.filter(item => item.isSelected == false && item.data.status == 'AA');
    if (rowSelectFalse.length > 0) 
      this.grdTags.isAllSelected = false;
    else 
      this.grdTags.isAllSelected = true;

    /*
    gridRow.impostos = this.dataIrf;
    if (gridRow.impostos != null || gridRow.impostos != undefined) {
      gridRow.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(gridRow.impostos);
    } else {
      gridRow.impostos = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(0);
    }
    */
    return gridRow.statusTable;
  }

  downloadExcel() {
    this.loading = true;

    this.anticipationService.getReport(this.payload).then((result) => {

      this.loading = false;

      let arquivo = result.toString();

      var stringBinary = window.atob(arquivo);
      var binarySize = stringBinary.length;
      var bytes = new Uint8Array(binarySize);
      for (var i = 0; i < binarySize; i++) {
        var ascii = stringBinary.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'application/xlsx' });
      const dataURL = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = "anticipation_report.xlsx";

      //alteração para funcionar no IE(Funciona nos outros também)
      var event = new MouseEvent('click');
      link.dispatchEvent(event);

    }
      , (err) => {
        alert("Erro ao baixar o excel.");
      });

  }
}
