import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  closeResult: string;
  exibirlogin = true;
  exibiresqueceusenha = false;
  exibirrenovarsenha = false;
  ShowHidePass = false;

  currentUser: any;

  deviceInfo = null;

  isAndroid = false;
  isiOs = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,private modalService :NgbModal,
    private deviceService: DeviceDetectorService) { 
      
      this.returnUrl = '/dashboard';
      
      //Se já tiver usuário no localstorage direciona para a home
      if (localStorage.getItem('CurrentUser')) {
        this.router.navigate([this.returnUrl]); 
      } 

    }

    deviceInfoFunction() {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      console.log(this.deviceInfo);

      const isMobile = this.deviceService.isMobile();
      console.log(isMobile); 
      const isTablet = this.deviceService.isTablet();
      console.log(isTablet);
      const isDesktopDevice = this.deviceService.isDesktop();  
      console.log(isDesktopDevice);

      if (isMobile && (this.deviceInfo.browser == 'Chrome' || this.deviceInfo.browser == 'Firefox' || this.deviceInfo.browser == 'Opera')){
        this.isAndroid = true;
        this.isiOs = false;
      } else if (isMobile){
        this.isiOs = true;
        this.isAndroid = false;
      }
    }

    ngOnInit() { 
      this.deviceInfoFunction();
    }

  ExibirSenha()
  {
    this.ShowHidePass = !this.ShowHidePass;
  }

  exibirEsqueceuSenha(){
    this.exibirlogin = false;
    this.exibiresqueceusenha = true;
    this.exibirrenovarsenha = false;
  }

  exibirLogin(){
    this.ShowHidePass = false;
    this.exibirlogin = true;
    this.exibiresqueceusenha = false;
    this.exibirrenovarsenha = false;
  }

  concordoTermos(){
    this.modalService.dismissAll({ariaLabelledBy: 'modal-termos'});

    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    let usernamesend = this.currentUser.username;

    this.loading = true;
    this.authenticationService.termsagree(usernamesend).subscribe(
      data => {
          if (data.message=="ok"){
            this.exibirlogin = false;
            this.exibiresqueceusenha = false;
            this.exibirrenovarsenha = true;
            this.loading = false;
          }          
      },
      error => {
        if (error.error)
                  if (!error.error.message)
                  //Quando não é possível acessar a api
                    this.alertService.error("Erro de comunicação com o servidor.");
                  else
                    this.alertService.error(error.error.message);
                else 
                  this.alertService.error("Erro ao tentar mostrar termos.");
        this.loading = true;      
      });       

  }

  openMODAL(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-termos', size: 'lg' });
  }

  login(content) {
    this.loading = true;
      this.authenticationService.login(this.model.username, this.model.password)
          .subscribe(
              data => {
                  if (data.user){
                    //Se usuário estiver ativo
                    if (data.user.status == "0")
                    {
                      let datenow = moment().format("YYYY-MM-DD");
                      if (datenow > data.user.valid_until)
                      {
                        this.alertService.error("Data de permissão de acesso passou do limite.");
                      }
                      else if (data.user.first_access == true && data.user.type == "UE")
                      {
                        //Se for primeiro acesso do tipo Fornecedor mostra termos
                        this.modalService.open(content, {ariaLabelledBy: 'modal-termos', size: 'lg' });
                      }
                      else if (data.user.first_access == true && data.user.type == "UL")
                      {
                        //Se for primeiro acesso do tipo Loja mostra tela para alterar senha
                        this.ShowHidePass = false;
                        this.exibirlogin = false;
                        this.exibiresqueceusenha = false;
                        this.exibirrenovarsenha = true;
                      }
                      else {                      
                        this.router.navigate([this.returnUrl]);                         
                      }  
                    } 
                    else
                    {
                      this.alertService.error("Usuário inativo.");
                    }
                  this.loading = false;                     
                  }                               
              },
              error => {
                if (error.error)
                  if (!error.error.message)
                  //Quando não é possível acessar a api
                    this.alertService.error("Erro de comunicação com o servidor.");
                  else
                    this.alertService.error(error.error.message);
                else 
                  this.alertService.error("Erro ao acessar portal.");
                
                this.loading = false; 
              }
          );
  }

  definirnovasenha(){
    this.loading = true;

    if(!(this.model.newpassword==this.model.newpassword2))
    {
      this.alertService.error("Nova Senha e Confirmar Nova Senha, devem ser iguais!");   
      this.loading = false; 
    }

    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    let usernamesend = this.currentUser.username;

    this.authenticationService.changepassword(usernamesend, this.model.newpassword).subscribe(
      data => {
          if (data.message=="Senha Alterada com Sucesso"){
            this.router.navigate([this.returnUrl]);        
          }
          
      },
      error => {
        if (error.error)
                  if (!error.error.message)
                  //Quando não é possível acessar a api
                    this.alertService.error("Erro de comunicação com o servidor.");
                  else
                    this.alertService.error(error.error.message);
                else 
                  this.alertService.error("Erro ao tentar alterar a senha.");
                
      });            

    this.loading = false; 
  }
  
  resetarSenha(){
    this.loading = true;

    this.authenticationService.resetpassword(this.model.usernameresetp).subscribe(
      data => {
        this.alertService.success(data.message);  
        this.loading=false;        
      },
      error => {
        if (error.error)
                  if (!error.error.message)
                  //Quando não é possível acessar a api
                    this.alertService.error("Erro de comunicação com o servidor.");
                  else
                    this.alertService.error(error.error.message);
                else 
                  this.alertService.error("Erro ao tentar resetar a senha.");
                  this.loading=false;    
      });     
  }

}
