import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeptPT from '@angular/common/locales/pt';
import localeptPTExtra from '@angular/common/locales/extra/pt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

import { LoginComponent } from './login/login.component';
import { RenovarsenhaComponent } from './renovarsenha/renovarsenha.component';
import { NavComponent } from './nav/nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PerfilusuarioComponent } from './perfilusuario/perfilusuario.component';
import { AlterarsenhaComponent } from './alterarsenha/alterarsenha.component';
import { CadastrofornecedorComponent } from './cadastrofornecedor/cadastrofornecedor.component';
import { DetalhefornecedorComponent } from './cadastrofornecedor/detalhefornecedor/detalhefornecedor.component';
import { CadastrousuariosgrupoComponent } from './cadastrousuariosgrupo/cadastrousuariosgrupo.component';
import { DetalheusuariosgrupoComponent } from './cadastrousuariosgrupo/detalheusuariosgrupo/detalheusuariosgrupo.component';
import { ContasapagarComponent } from './contasapagar/contasapagar.component';
import { DownloadcomprovanteComponent } from './contasapagar/downloadcomprovante/downloadcomprovante.component';
import { DetalhevalordevolucaoComponent } from './modaldetalhes/detalhevalordevolucao/detalhevalordevolucao.component';
import { DetalheirfComponent } from './modaldetalhes/detalheirf/detalheirf.component';
import { DetalheocorrenciaComponent } from './modaldetalhes/detalheocorrencia/detalheocorrencia.component';
import { SolicitarantecipacaoComponent } from './solicitarantecipacao/solicitarantecipacao.component';
import { ConfirmComponent } from './solicitarantecipacao/confirm/confirm.component';
import { InfoComponent } from './solicitarantecipacao/info/info.component';
import { AcompanharantecipacaoComponent } from './acompanharantecipacao/acompanharantecipacao.component';
import { RodapeComponent } from './rodape/rodape.component';
import { ContatoComponent } from './contato/contato.component';
import { AjudaComponent } from './ajuda/ajuda.component';
import { SidebarperfilComponent } from './sidebarperfil/sidebarperfil.component';
import { SidebarperfildadosComponent } from './sidebarperfildados/sidebarperfildados.component';
import { AvisoComponent } from './aviso/aviso.component';

import { AuthenticationService } from './services/auth.service';
import { ContactsService } from './services/contact.service';
import { OtherusersService } from './services/otherusers.service';
import { CompaniesService } from './services/companies.service';
import { NfsService } from './services/nfs.service';
import { NewsService } from './services/news.service';
import { HelpService } from './services/help.service';
import { AlertComponent } from './directives/alert.component';
import { AlertService } from './services/alert.service';
import { ContactUsService } from './services/contactus.service';
import { AnticipationService } from './services/anticipation.service';
import { BillsToPayPDFService } from './services/bills-to-pay-pdf';
import { ImageService } from './services/image.service';

import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { DropzoneModule, DropzoneConfigInterface, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartsModule } from 'ng2-charts';

import { EnvServiceProvider } from './services/env/env.service.provider';

import { GraficocontasapagarComponent } from './graficocontasapagar/graficocontasapagar.component';
import { GraficoacompanharantecipacaoComponent } from './graficoacompanharantecipacao/graficoacompanharantecipacao.component';
import { GraficosolicitarantecipacaoComponent } from './graficosolicitarantecipacao/graficosolicitarantecipacao.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { MessageblockComponent } from './solicitarantecipacao/messageblock/messageblock.component'; 

import { DeviceDetectorModule } from 'ngx-device-detector';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  createImageThumbnails: true,
};
registerLocaleData(localeptPT, 'pt', localeptPTExtra);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    DashboardComponent,
    PerfilusuarioComponent,
    AlterarsenhaComponent,
    CadastrofornecedorComponent,
    DetalhefornecedorComponent,
    DetalhevalordevolucaoComponent,
    DetalheirfComponent,
    DetalheocorrenciaComponent,
    CadastrousuariosgrupoComponent,
    DetalheusuariosgrupoComponent,
    ContasapagarComponent,
    DownloadcomprovanteComponent,
    SolicitarantecipacaoComponent,
    ConfirmComponent,
    InfoComponent,
    AcompanharantecipacaoComponent,
    RodapeComponent,
    ContatoComponent,
    AjudaComponent,
    SidebarperfilComponent,
    AlertComponent,
    SidebarperfildadosComponent,
    AvisoComponent,
    RenovarsenhaComponent,
    GraficocontasapagarComponent,
    GraficoacompanharantecipacaoComponent,
    GraficosolicitarantecipacaoComponent,
    MessageblockComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DropzoneModule,
    BsDatepickerModule.forRoot(),
    MultiselectDropdownModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,  
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.28)',
      backdropBorderRadius: '10px',
      primaryColour: '#2386ca', 
      secondaryColour: '#2386ca', 
      tertiaryColour: '#2386ca'
    }),
    Ng2SmartTableModule,
    ChartsModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    AuthenticationService, 
    AlertService, 
    ContactsService, 
    OtherusersService,
    CompaniesService,
    NfsService,
    NewsService,
    HelpService,
    ContactUsService,
    AnticipationService,
    BillsToPayPDFService,
    ImageService,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    EnvServiceProvider
  ],
  entryComponents : 
  [DetalheusuariosgrupoComponent,
    DetalhefornecedorComponent, 
    ConfirmComponent,
    InfoComponent,
    DetalhevalordevolucaoComponent,
    DetalheirfComponent,
    DetalheocorrenciaComponent,
    DownloadcomprovanteComponent,
    MessageblockComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
