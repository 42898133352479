import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactsService } from '../services/contact.service';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-sidebarperfildados',
  templateUrl: './sidebarperfildados.component.html',
  styleUrls: ['./sidebarperfildados.component.css']
})
export class SidebarperfildadosComponent implements OnInit {
  
  currentUser: any;
  principalMail: any;
  loading = false;
  returnUrlLogin = '/login';

  constructor(private contactsService: ContactsService,
    private route: ActivatedRoute,
    private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    
    //Validação dados de contato       
    if (!localStorage.getItem('ContactUser')) 
    {
      this.getContacts();
    } 
    else
    {
      let contactUser = JSON.parse(localStorage.getItem('ContactUser'));   
      this.principalMail =  contactUser[0].email;
    }    
  }

  getContacts(){
  this.loading = true;
  this.contactsService.getContacts(this.currentUser.username, this.currentUser.supplier_id)
  .subscribe(
    data => {
      data = Object.keys(data.contacts).map(function (key) { return data.contacts[key]; });
      this.principalMail = data[0].email;
      this.loading = false;
    },
    error => {
        alert("Erro ao buscar e-mail principal do contato");
        this.loading = false;
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate([this.returnUrlLogin]); 
  }

}
