import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RenovarsenhaComponent } from './renovarsenha/renovarsenha.component';
import { NavComponent } from './nav/nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PerfilusuarioComponent } from './perfilusuario/perfilusuario.component';
import { AlterarsenhaComponent } from './alterarsenha/alterarsenha.component';
import { CadastrofornecedorComponent } from './cadastrofornecedor/cadastrofornecedor.component';
import { CadastrousuariosgrupoComponent } from './cadastrousuariosgrupo/cadastrousuariosgrupo.component';
import { ContasapagarComponent } from './contasapagar/contasapagar.component';
import { SolicitarantecipacaoComponent } from './solicitarantecipacao/solicitarantecipacao.component';
import { AcompanharantecipacaoComponent } from './acompanharantecipacao/acompanharantecipacao.component';
import { ContatoComponent } from './contato/contato.component';
import { AjudaComponent } from './ajuda/ajuda.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'renovarsenha', component: RenovarsenhaComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'perfilusuario', component: PerfilusuarioComponent },
  { path: 'alterarsenha', component: AlterarsenhaComponent },
  { path: 'cadastrofornecedor', component: CadastrofornecedorComponent },
  { path: 'cadastrousuariosgrupo', component: CadastrousuariosgrupoComponent },
  { path: 'contasapagar', component: ContasapagarComponent },
  { path: 'solicitarantecipacao', component: SolicitarantecipacaoComponent },
  { path: 'acompanharantecipacao', component: AcompanharantecipacaoComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'ajuda', component: AjudaComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
