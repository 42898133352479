
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class HelpService {

  urlAPI = ENV.API_URL;

  constructor(private http: HttpClient) { }

  help(username:string,supplier:string){
    var controllerRoute = "api/help";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username, supplier:supplier})
        .map(data => {
            return data;
        });
}
}
