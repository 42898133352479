import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class CompaniesService {
  urlAPI = ENV.API_URL;

  constructor(private http: HttpClient) { }

  getCompanies(id: string) {
    var controllerRoute = "api/companies";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { sign: "I", option: "EQ", low: id })
        .map(data => {
            return data;
        });
  }

  downloadExcel(id: string) {
    var controllerRoute = "api/companies/excelreport";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { sign: "I", option: "EQ", low: id })
        .map(data => {
            return data;
        });
  }
}
