import { Component, Input, OnInit } from '@angular/core';
import { BillsToPayPDFService } from '../../services/bills-to-pay-pdf';


@Component({
    selector: 'app-downloadcomprovante',
    templateUrl: './downloadcomprovante.component.html',
})
export class DownloadcomprovanteComponent implements OnInit {

    public renderValue;
    loading = false;
    nome_comprovante: string;
    @Input() rowData;

    constructor(private billstoPayPDFService: BillsToPayPDFService) { }

    ngOnInit() {
        this.renderValue = this.rowData;

        if (this.renderValue.nome_comprovante != null || this.renderValue.nome_comprovante != undefined)
            this.nome_comprovante = this.renderValue.nome_comprovante
        else
            this.nome_comprovante = "";
    }

    downloadComprovante() {
        //this.loading = true;

        this.billstoPayPDFService.getReceipt(this.renderValue.comprovante)
            .subscribe(
                result => {
                    
                    //this.loading = false;

                    let arquivo = result['file'];

                    var stringBinary = window.atob(arquivo);
                    var binarySize = stringBinary.length;
                    var bytes = new Uint8Array(binarySize);
                    for (var i = 0; i < binarySize; i++) {
                        var ascii = stringBinary.charCodeAt(i);
                        bytes[i] = ascii;
                    }

                    const blob = new Blob([bytes], { type: 'application/pdf' });
                    const dataURL = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = dataURL;
                    link.download = this.nome_comprovante + ".pdf";

                    //alteração para funcionar no IE(Funciona nos outros também)
                    var event = new MouseEvent('click');
                    link.dispatchEvent(event);

                }
                , (err) => {
                    alert("Erro ao baixar o comprovante.");
                });
    }
}
