import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactsService } from '../../services/contact.service';

@Component({
  selector: 'app-detalheusuariosgrupo',
  templateUrl: './detalheusuariosgrupo.component.html',
})
export class DetalheusuariosgrupoComponent implements OnInit {

  public renderValue;

  usershow: any;
  contactUser:any;
  currentUser: any;
  loading = false;

  @Input() rowData;

  constructor(private modalService :NgbModal,private contactsService: ContactsService) {  }

  ngOnInit() {
    this.renderValue = this.rowData;
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser')); 
   
  }
  
  detalheregistro(content)
  {      
      this.usershow = this.renderValue;         
      
      //Validação dados de contato       
      if (!localStorage.getItem('ContactUser')) 
      {
        this.getContacts();
      } 
      else
      {
        this.contactUser = JSON.parse(localStorage.getItem('ContactUser'));   
      } 
      this.modalService.open(content, {ariaLabelledBy: 'modal-detalhe', size: 'lg' });
  }

  getContacts(){
    this.loading = true;
    this.contactsService.getContacts(this.currentUser.username, this.currentUser.supplier_id)
    .subscribe(
      data => {
        this.contactUser = Object.keys(data.contacts).map(function (key) { return data.contacts[key]; });
        this.loading = false;
      },
      error => {
          alert("Erro ao buscar dados do contato");
          this.loading = false;
      });
    }


}