import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OtherusersService } from '../services/otherusers.service';
import { AlertService } from '../services/alert.service';

import { ServerDataSource } from 'ng2-smart-table';
import { DetalheusuariosgrupoComponent } from './detalheusuariosgrupo/detalheusuariosgrupo.component';

import * as moment from 'moment';
import { Router } from '@angular/router';
import _ from 'lodash';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-cadastrousuariosgrupo',
  templateUrl: './cadastrousuariosgrupo.component.html',
  styleUrls: ['./cadastrousuariosgrupo.component.css']
})
export class CadastrousuariosgrupoComponent implements OnInit {

  faFileExcel = faFileExcel;
  currentUser: any;
  otherusers: any;
  loading = false;
  usershow: any;
  hideCols: any;
  keys: any;
  viewShowInfoAdc = false;
  permissions: any;

  settings;
  mySettings = {
    actions: false,
    columns: {
      username: {
      title: 'Usuário',
      isSelected: true
      },
      name: {
      title: 'Nome',
      isSelected: true
      },
      valid_until: {
      valuePrepareFunction: (cell, row) => { return moment(row.valid_until).format("DD/MM/YYYY").toString(); },
      title: 'Válido até',
      isSelected: true
      },
      //flag: {
      //title: 'Status',
      //isSelected: false
      //},
      button: {
        title: '',
        type: 'custom',
        renderComponent: DetalheusuariosgrupoComponent,
        filter: false,
        isSelected: true
      },
    },    
    pager: {
      display: false
    }
  };

  source: ServerDataSource;
  
  constructor(private otheruserService: OtherusersService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private modalService :NgbModal, http: HttpClient,
    private router: Router) { 
      authService.verifyPermissionCadastroUusuariosGrupo();

      this.settings = Object.assign({}, this.mySettings)
      this.keys = Object.keys;
    }
      
  ngOnInit() {    
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));  
    this.getOtherUsers();
  }

  getOtherUsers(){
    this.loading = true;
    this.otheruserService.otherusers(this.currentUser.supplier_id,this.currentUser.username, "R")
    .subscribe(
      data => {
        this.otherusers = Object.keys(data.users).map(function (key) {
          return data.users[key];
        });
        
        //Buscar todos usuários exceto o que está logado
        this.otherusers = this.otherusers.filter(users => users.username != this.currentUser.username);
        
        this.source = this.otherusers;
        this.loading = false;
      },
      error => {
          this.alertService.error("Erro ao buscar dados.");
          this.loading = false;
      });     
  }

  public updateCols($event) {
 
    this.hideCols = '';
    let i = 0;
    for (const colI in this.settings.columns) {
      if (this.settings.columns[colI].isSelected) {
        i++;
      } else {
        i++;
        this.hideCols += `hd-cl-${i} `;
      }
    }
  }

  ShowInfoAdc()
  {
    if(!this.viewShowInfoAdc)
      this.viewShowInfoAdc = true;
    else
      this.viewShowInfoAdc = false;
  }
  
}
