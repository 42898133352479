import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalhevalordevolucao',
  templateUrl: './detalhevalordevolucao.component.html',
})
export class DetalhevalordevolucaoComponent implements OnInit {

  public renderValue;

  data: any;
  valor_devolucao: string;
  @Input() rowData;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.renderValue = this.rowData;
    this.valor_devolucao = this.renderValue.dmbtr_crd;
  }

  detalheVD(content) {
    this.data = this.renderValue;

    this.modalService.open(content, { ariaLabelledBy: 'modal-detalheVD', size: 'lg' });
  }


}