import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ENV } from '../../config/config';

import { AlertService } from '../../services/alert.service';
import { AnticipationService } from '../../services/anticipation.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'confirm-component',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})

export class ConfirmComponent implements OnInit {

    loading = false;

    faQuestionCircle = faQuestionCircle;

    @Input('parentData') resumo: any;

    payload = {
        username: '',
        items: []
    }

    currentUser: any;

    //variáveis para alterar campos em produção
    currentURL = '';
    showTaxa: boolean = false;
    showLiquidoAntecipar: boolean = false;
    showJuros: boolean = false;
    colunaAntecipacao: string = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private alertService: AlertService,
        private provider: AnticipationService) {

        this.currentURL = window.location.href;
        
        //Ocultar o campo Taxa
        //Ocultar campo Juros
        //Mudar o nome da coluna de Data de Antecipação para Data de Solicitação
        if (this.currentURL.includes(ENV.PROD_URL)) {
            this.showTaxa = false;
            this.showJuros = false;
            this.showLiquidoAntecipar = false;
            this.colunaAntecipacao = 'Data de Solicitação';
        } else {
            this.showTaxa = true;
            this.showLiquidoAntecipar = true;
            this.showJuros = true;
            this.colunaAntecipacao = 'Data de Antecipação';
        }
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    }

    openConfirmModal(content) {
        if (this.resumo.selectedItensToAntecipate.length > 0) {
            this.payload.username = this.currentUser.username;
            this.payload.items = this.resumo.selectedItensToAntecipate.filter(item => item.status == "AA");
            this.modalService.open(content, { ariaLabelledBy: 'modal-confirm', size: 'lg', backdrop: 'static', });
        } else {
            this.alertService.error("É necessário selecionar no mínimo um item para antecipar.");
        }

    }

    confirm() {
        this.loading = true;
        this.provider.send(this.payload).then(
            result => {
                let return_msg : String = "Solicitação enviada com sucesso!";
                
                if (!result) {
                    return_msg = "Erro na Solicitação. Contate o Administrador.";
                }

                this.loading = false;
                this.modalService.dismissAll();
                alert(return_msg);

                if (result)
                    this.router.navigate(['/dashboard']);
            },
            err => {
                this.alertService.error("Erro ao enviar solicitação.");
                this.loading = false;
            }
        );
    }

    cancel() {
        this.modalService.dismissAll();
    }
}