import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { OtherusersService } from '../services/otherusers.service';
import { CompaniesService } from '../services/companies.service';
import { NfsService } from '../services/nfs.service';
import { ImageService } from '../services/image.service';
import { AlertService } from '../services/alert.service';

import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loadingqtduser = false;
  loadingctpagar = false;
  loadingempresa = false;
  loadingimage = false;

  currentUser: any;
  suppliers: any;

  otherusers: any;
  companies: any;
 
  qtdUsers: number = 0;
  qtdCompanies: number = 0;
  NFs: any;
  qtdNfs: number = 0;
  VlrNfs: number = 0;

  returnUrlLogin = '/login';

  BillsPayload = {
    username: '',
    companyCode: [],
    costCenter: null,
    id: null,
    dueDateFrom: '',
    dueDateUntil: '',
    status: 0
  };

  permissions: any;
  hasContaPagar = false;
  hasSolicitarAntecipacao = false;
  hasAcompanharAntecipacao = false;
  hasCadastro = false;
  hasUsuarios = false;

  image: SafeResourceUrl;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private otheruserService: OtherusersService,
    private CompaniesService: CompaniesService,
    private NfsService: NfsService,
    private imageService: ImageService,
    private alertService: AlertService, 
    private sanitizer: DomSanitizer) {

      if (!localStorage.getItem('CurrentUser')) {
        this.router.navigate([this.returnUrlLogin]);
      }
      else {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));
    this.permissions = JSON.parse(localStorage.getItem('Permissions'));

    let i = 0;
    for (i = 0; i < this.permissions.length; i++) {
      if (this.permissions[i] == "PTIT") {
        this.hasContaPagar = true;
      }
      else if (this.permissions[i] == "ADFN") {
        this.hasCadastro = true;
      }
      else if (this.permissions[i] == "SOAF") {
        this.hasSolicitarAntecipacao = true;
      }
      else if (this.permissions[i] == "ACAF") {
        this.hasAcompanharAntecipacao = true;
      }
      else if (this.permissions[i] == "ADUS") {
        this.hasUsuarios = true;
      }
    }
    }
  }

  ngOnInit() {   
    this.getOtherUsers();
    this.getCompanies();
    this.getNfs();   
    this.loadImage(); 
  }

  getOtherUsers() {
    this.loadingqtduser = true;
    this.qtdUsers = 0;
    this.otheruserService.otherusers(this.currentUser.supplier_id, this.currentUser.username, "R")
      .subscribe(
        data => {
          this.otherusers = Object.keys(data.users).map(function (key) { return data.users[key]; });
          
          if (this.otherusers)
            this.qtdUsers = this.otherusers.length;
          
          this.loadingqtduser = false;
        },
        error => {
          this.loadingqtduser = false;
          this.alertService.error("Erro ao buscar dados.");          
        });
  }

  getCompanies() {
    this.loadingempresa = true;
    this.qtdCompanies = 0;

    this.CompaniesService.getCompanies(this.currentUser.supplier_id)
      .subscribe(
        data => {
          this.companies = Object.keys(data.companies).map(function (key) { return data.companies[key]; });

          if (this.companies)
            this.qtdCompanies = this.companies.length;

          this.loadingempresa = false;
        },
        error => {
          this.loadingempresa = false;
          this.alertService.error("Erro ao buscar dados.");
        });
  }

  public prepareBillsPayload() {
    /* objeto para acesso no web-service */
    if (this.currentUser.type == 'UL') {
      //UL = Usuario Loja           
      //Prctr = codigo do centro de lucro (id da loja)
      this.BillsPayload.costCenter = this.currentUser.cost_center;
    }
    else {
        //UE = Usuario fornecedor    
        //Lifnr = codigo do forncedor 
        this.suppliers.map((item) => {
          this.BillsPayload.companyCode.push(item.id);
        });
        if(this.suppliers[0])
          this.BillsPayload.id = this.suppliers[0].company;
    }
    
    this.BillsPayload.username = this.currentUser.username;
    //retorna somente registro em aberto
    this.BillsPayload.status = 1;

    let today = new Date();

    let until = moment(today).format("YYYY-MM-DD");
    let from = moment(today).subtract(1, 'months').format("YYYY-MM-DD");

    this.BillsPayload.dueDateFrom = from;
    this.BillsPayload.dueDateUntil = until;
    /* fim do objeto para busca no web-service */
  }

  getNfs() { // Qtd e Vlr
    this.loadingctpagar = true;

    this.prepareBillsPayload();

    this.qtdNfs = 0;
    this.VlrNfs = 0;

    this.NfsService.getNFs(this.BillsPayload)
      .then(
        (data) => {
          this.NFs = data;
          if (this.NFs) {
              let qtditems = 0;
              let vlrtotal = this.NFs.total_open;

              this.NFs.items.forEach(item => {
                if (item.rows) {
                  item.rows.forEach(itemrow => {
                      qtditems += 1;
                  });
                }
              });
              
              this.qtdNfs = qtditems;
              this.VlrNfs = vlrtotal;
              this.loadingctpagar = false;
          }
        },
        error => {
          this.alertService.error("Erro ao buscar dados.");
        });
  }

  loadImage() {
    this.loadingimage = true;
    this.image = "";
    this.imageService.loadImage().then((result) => {
      if(result['image']){
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64, ' + result['image']);
      }
      this.loadingimage = false;
    }
    , (err) => {
      this.loadingimage = false;
      alert("Erro ao buscar imagem.");
    });

  }


}