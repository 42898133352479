import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()
export class ContactUsService {

    urlAPI = ENV.API_URL;
    header = new HttpHeaders()

    constructor(public http: HttpClient) {
        this.header.append('Content-type', 'application/json')
        this.header.append('Access-Control-Allow-Origin', '*')
      }
    
      getOptions(data) {
        return new Promise((resolve, reject) => {
          this.http.post(this.urlAPI+'/api/contact-us/config', JSON.stringify(data), {headers : this.header})
            .subscribe(
              res => { resolve(res) },
              err => { reject(err) }
            )
        })
      }

      sendContact(data) {
        return new Promise((resolve, reject) => {
          this.http.post(this.urlAPI+'/api/contact-us/send', JSON.stringify(data), { headers : this.header })
            .subscribe(
              res => { resolve(res) },
              err => { reject(err) }
            )
        })
      }
}