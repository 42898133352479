import { Component } from '@angular/core';
import {ViewEncapsulation} from '@angular/core';  
import { Router, ActivatedRoute } from '@angular/router';

import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

import { AuthenticationService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    '../assets/css/components-rounded.css',
    '../assets/css/layout.css',
    '../assets/css/default.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Portal Fornecedor'; 

  timedOut = false;

  constructor(private idle: Idle, 
    private keepalive: Keepalive,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,) {
    
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    //verifica por 10 minutos a inatividade => a biblioteca considera segundos
    idle.setIdle(600);

    //1 segundo após os 10 minutos de verificação de inatividade, encerra a sessão
    idle.setTimeout(1);
    
    //idle.onIdleStart.subscribe(() => console.log('You\'ve gone idle!'));
    //idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    
    idle.onTimeout.subscribe(() => {
      //console.log('Timed out!');
      this.timedOut = true;
      authService.logout();
      if (this.router.url != '/login')
        this.router.navigate(['/login']);
      this.reset();
    });   

    this.reset();
  }

  //Recomeça a contar
  reset() {
    this.idle.watch();       
    this.timedOut = false; 
    //console.log('Started.');
  }
}
