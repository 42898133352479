import { Component, OnInit, ViewChild } from '@angular/core';
import { AnticipationService } from '../services/anticipation.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graficosolicitarantecipacao',
  templateUrl: './graficosolicitarantecipacao.component.html',
  styleUrls: ['./graficosolicitarantecipacao.component.css']
})
export class GraficosolicitarantecipacaoComponent implements OnInit {

  @ViewChild('GaugeChart1')
  public chartGauge: BaseChartDirective;

  suppliers: any;

  AnticipationPayload = {
    companyCode: [],
    id: null
  };

  loadingAntecipationGauge = true;
  solicitarAntecipacao: any = {
    apto: 0,
    inapto: 0
  };

  public GaugeChartData: any = [{
    data: [0, 100],
    backgroundColor: ["rgb(69, 182, 175)", "rgb(198, 198, 198)"],
    borderWidth: 0,
    hoverBackgroundColor: ["rgb(55, 150, 144)", "rgb(168, 168, 168)"],
    hoverBorderWidth: 0
  }];
  public GaugeChartOpts: any = {
    chartType: 'doughnut',
    colors: ["rgb(69, 182, 175)", "rgb(198, 198, 198)"],
    options: {
      responsive: true,
      cutoutPercentage: 60,
      rotation: -3.1415926535898,
      circumference: 3.1415926535898,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      title: {
        display: false,
        text: '000',
        position: "bottom"
      }
    },
  }

  constructor(
    private anticipationService: AnticipationService,
    private alertService: AlertService, ) {
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));
  }

  ngOnInit() {
    this.getSuppliers();
    this.graphAntecipation();

    this.loadingAntecipationGauge = true;
  }

  getSuppliers() {
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));

    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    this.suppliers.map((item) => {
      this.AnticipationPayload.companyCode.push(item.id);
    });

    this.AnticipationPayload.id = supplier.company;
  }

  private graphAntecipation() {

    this.anticipationService.getAvailable(this.AnticipationPayload).then((result) => {
      let tav: string = result['total_available'];
      let tunav: string = result['total_not_available'];

      let tavParsed: string = tav.replace(/[.]/g, '').replace(/[,]/g, '.');
      let tunavParsed: string = tunav.replace(/[.]/g, '').replace(/[,]/g, '.');

      this.solicitarAntecipacao.apto = tav;
      this.solicitarAntecipacao.inapto = tunav;

      this.GaugeChartData = [{
        data: [tavParsed, tunavParsed],
        backgroundColor: ["rgb(69, 182, 175)", "rgb(198, 198, 198)"],
        borderWidth: 0,
        hoverBackgroundColor: ["rgb(55, 150, 144)", "rgb(168, 168, 168)"],
        hoverBorderWidth: 0
      }];

      this.chartGauge.chart.update();
      this.loadingAntecipationGauge = false;
    },
      error => {
        this.loadingAntecipationGauge = true;
        this.alertService.error("Erro ao buscar dados.");
        this.loadingAntecipationGauge = false;
      });

  }
}
