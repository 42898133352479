import { Component, OnInit, ViewChild } from '@angular/core';
import { AnticipationService } from '../services/anticipation.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';

import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graficoacompanharantecipacao',
  templateUrl: './graficoacompanharantecipacao.component.html',
  styleUrls: ['./graficoacompanharantecipacao.component.css']
})
export class GraficoacompanharantecipacaoComponent implements OnInit {
  
  @ViewChild('chartFollowUpAnticipation')
  public chartFollowUpAnticipation: BaseChartDirective;

  flowloading = true;
  currentUser: any;
  suppliers: any;

  anticipation = {
    companyCode: [],
    costCenter: null,
    id: null,
    status: [],
    dateFrom: null,
    dateUntil: null
  };

  constructor(
    private anticipationService: AnticipationService,
    private alertService: AlertService,) {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.suppliers = JSON.parse(localStorage.getItem('Suppliers'));
  }

  dataResponseFollowUpAnticipation: any = [];
  countFollowUpAnticipationAproved: number = 0;
  countFollowUpAnticipationPending: number = 0;
  countFollowUpAnticipationReject: number = 0;

   //Gráfico de acompanhamento de antecipação
  doughnutChartData: number[] = [];
  doughnutChartLabels: string[] = ["Aprovado", "Pendente", "Rejeitado"];
  public doughnutChartOptions: any = {
    responsive: true,
    legend: false,
    animation: false
  };
  doughnutChartColors: any[] = [{ backgroundColor: ["#45B6AF", "#dfba49", "#F3565D"] }];
  doughnutChartType: string = 'doughnut';

  ngOnInit() {
    this.graphFollowUpAnticipation();
  }

  graphFollowUpAnticipation() {

    this.flowloading = true;

    let supplier = this.suppliers.filter((el) => {
      return el.default;
    }).shift();

    if (this.currentUser.type == 'UL') {
      this.anticipation.costCenter = this.currentUser.supplier_id;
    } else if (this.suppliers) {
      this.suppliers.map((item) => {
        this.anticipation.companyCode.push(item.id);
      });
      this.anticipation.id = supplier.company;
    }

    let today = moment();
    let from = today.format("YYYY-MM-DD");
    let until = today.add(30, 'days').format("YYYY-MM-DD");

    this.anticipation.dateFrom = from;
    this.anticipation.dateUntil = until;

    this.anticipationService.getFollowUps(this.anticipation).then((result) => {
      this.flowloading = true;

      this.dataResponseFollowUpAnticipation = result['items'];

      let formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2
      });

      var array = [];

      this.dataResponseFollowUpAnticipation.forEach(element => {
        element.rows.forEach(e => {
          if (e.status == "A")
            this.countFollowUpAnticipationAproved += 1;
          else if (e.status == "R")
            this.countFollowUpAnticipationReject += 1;
          else if (e.status == "P")
            this.countFollowUpAnticipationPending += 1;
        });
      });

      this.doughnutChartData.push(this.countFollowUpAnticipationAproved);
      this.doughnutChartData.push(this.countFollowUpAnticipationPending);
      this.doughnutChartData.push(this.countFollowUpAnticipationReject);

      this.chartFollowUpAnticipation.chart.update();

      this.flowloading = false;

    }, (err) => {
      this.flowloading = true;
      this.alertService.error("Erro ao buscar dados.");
      this.flowloading = false;
    });

  }
  

}
