import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-detalheocorrencia',
    templateUrl: './detalheocorrencia.component.html',
})
export class DetalheocorrenciaComponent implements OnInit {

    public renderValue;

    data: any;
    log: [];
    ocorrencia: string;
    @Input() rowData;

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.renderValue = this.rowData;
        this.log = this.renderValue.log;
        this.ocorrencia = this.renderValue.ocorrencia;
    }

    detalheLOD(content) {
        this.data = this.renderValue;

        this.modalService.open(content, { ariaLabelledBy: 'modal-detalheLOD' });
    }


}