import { Component, OnInit } from '@angular/core';
import { HelpService } from '../services/help.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.css']
})
export class AjudaComponent implements OnInit {

  currentUser: any;
  help: any;
  loading = false;

  constructor(private helpService: HelpService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));  
    this.getHelp();
  }

  getHelp(){
    this.loading = true;
    this.helpService.help(this.currentUser.username,this.currentUser.supplier_id)
    .subscribe(
      data => {
        this.help = data;
        this.loading = false;
      },
      error => {
          this.alertService.error("Erro ao buscar dados.");
          this.loading = false;
      });     
  }

  donwloadfile(help)
  {
    var stringBinary = window.atob(help.file);
    var binarySize = stringBinary.length;
    var bytes = new Uint8Array(binarySize);
    for (var i = 0; i < binarySize; i++) {
            var ascii = stringBinary.charCodeAt(i);
            bytes[i] = ascii;
    }
  
    const blob = new Blob([bytes], {type: 'application/pdf'});
    const dataURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = help.name + ".pdf";
    
    //alteração para funcionar no IE(Funciona nos outros também)
    var event = new MouseEvent('click');
    link.dispatchEvent(event);
    
  }
    
}


