import { Component, OnInit } from '@angular/core';
import { NewsService } from '../services/news.service';
import { AlertService } from '../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.css']
})
export class AvisoComponent implements OnInit {

  currentUser: any;
  news: any;
  loading = false;
  total_unread: any;
  newshow: any;
  shownewagain:boolean;

  constructor(private newsService: NewsService,
    private alertService: AlertService,
    private modalService :NgbModal) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));  
    this.getNews();
  }

  getNews(){
    this.loading = true;
    this.newsService.news(this.currentUser.username)
    .subscribe(
      data => {
        this.news = Object.keys(data.news).map(function (key) { return data.news[key]; });
        
        this.total_unread = data.total_unread;

        if(this.total_unread<=1)
        {
          this.total_unread = this.total_unread + " NÃO LIDO";
        }
        else
        {
          this.total_unread = this.total_unread + " NÃO LIDOS";
        }
        
        this.news = this.news.filter(news => news.hidden === false);

        this.loading = false;
      },
      error => {
          this.alertService.error("Erro ao buscar dados.");
          this.loading = false;
      });     
  }

  detalheaviso(newr,content)
  {
    this.loading = true; 
    this.newshow = newr;
    this.shownewagain = false;

    this.newsService.newsread(newr.id, this.currentUser.username).subscribe(
      data => {
          if (data.status=="0"){
            this.loading = false; 
            this.modalService.open(content, {ariaLabelledBy: 'modal-aviso-detalhe', size: 'lg' });  
          } 
          else 
          {
            this.loading = false; 
            this.alertService.error("Erro ao exibir aviso."); 
          }        
      },
      error => {
        if (error.error)
          if (!error.error.message)
          //Quando não é possível acessar a api
            this.alertService.error("Erro de comunicação com o servidor.");
          else
            this.alertService.error(error.error.message);
        else 
          this.alertService.error("Erro ao exibir aviso.");
        
        this.loading = false; 
      });          
  }

  naoExibirAviso(idnew)
  {
    this.loading = true; 
    this.getNews();    

    if(!this.shownewagain)
    {
      this.modalService.dismissAll({ariaLabelledBy: 'modal-aviso-detalhe'}); 
    }
    else
    {
      this.newsService.newsdismiss(idnew, this.currentUser.username).subscribe(
        data => {
            if (data.status=="0"){
              this.loading = false; 
              this.getNews();
              this.modalService.dismissAll({ariaLabelledBy: 'modal-aviso-detalhe'}); 
            } 
            else 
            {
              this.loading = false; 
              this.alertService.error("Erro ao gravar não exibir aviso novamente."); 
            }        
        },
        error => {
          if (error.error)
            if (!error.error.message)
            //Quando não é possível acessar a api
              this.alertService.error("Erro de comunicação com o servidor.");
            else
              this.alertService.error(error.error.message);
          else 
            this.alertService.error("Erro ao gravar não exibir aviso novamente.");
          
          this.loading = false; 
        }); 
    }
  }

}
