import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-alterarsenha',
  templateUrl: './alterarsenha.component.html',
  styleUrls: ['./alterarsenha.component.css']
})
export class AlterarsenhaComponent implements OnInit {
  model: any = {};
  currentUser: any;
  loading = false;
  ShowHidePass = false;

  constructor(private alertService: AlertService,
    private authenticationService: AuthenticationService) { 
    
  }

  ngOnInit() {
  }
  
  ExibirSenha()
  {
    this.ShowHidePass = !this.ShowHidePass;
  }

  mudarsenha(){

    this.loading = true;

    
    if(!(this.model.newpassword==this.model.newpassword2))
    {
      this.alertService.error("Nova Senha e Confirmar Nova Senha, devem ser iguais!");   
      this.loading = false; 
      return;
    }

    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    let usernamesend = this.currentUser.username;

    this.authenticationService.changepassword(usernamesend, this.model.newpassword).subscribe(
      data => {
          if (data.message=="Senha Alterada com Sucesso"){
            this.alertService.success("Senha Alterada com Sucesso");
          }
          this.loading = false; 
      },
      error => {
        if (error.error)
                  if (!error.error.message)
                  //Quando não é possível acessar a api
                    this.alertService.error("Erro de comunicação com o servidor.");
                  else
                    this.alertService.error(error.error.message);
                else 
                  this.alertService.error("Erro ao tentar alterar a senha.");
        this.loading = false; 
                
      });           
  }

}
