import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class NfsService {
    urlAPI = ENV.API_URL;
    header = new HttpHeaders()

    constructor(public http: HttpClient) {
        this.header.append('Content-type', 'application/json')
        this.header.append('Access-Control-Allow-Origin', '*')
    }
    
    getNFs(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.urlAPI + '/api/bills-to-pay', JSON.stringify(data), { headers: this.header }).subscribe(
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }

    getNFsformated(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.urlAPI + '/api/bills-to-pay/formatedList', JSON.stringify(data), { headers: this.header }).subscribe(
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }

    downloadExcel(data) {
        return new Promise((resolve, reject) => {
            this.http.post(this.urlAPI + '/api/bills-to-pay/excelreport', JSON.stringify(data), { headers: this.header }).subscribe(
                res => { resolve(res); },
                err => { reject(err); }
            );
        });
    }
}
