import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import { AlertService } from '../../services/alert.service';
import { AnticipationService } from '../../services/anticipation.service';


@Component({
    selector: 'info-component',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css']
})

export class InfoComponent implements OnInit {

    faQuestionCircle = faQuestionCircle;
    loading = false;

    @Input('parentData') resumo: any;

    payload = {
        username: '',
        items: []
    }

    currentUser: any;

    constructor(private modalService: NgbModal) { }

    ngOnInit() { }    

    valorLiquido(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-valor-liquido', size: 'sm' });
    }
}