import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()

export class OtherusersService {

  urlAPI = ENV.API_URL;

  constructor(private http: HttpClient) { }

  otherusers(id: string, username:string,operation:string){
    var controllerRoute = "api/other-users";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { id:id, username: username, operation:operation })
        .map(data => {
            return data;
        });
}
}
