import { Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-messageblock',
  templateUrl: './messageblock.component.html',
  styleUrls: ['./messageblock.component.css']
})
export class MessageblockComponent implements OnInit {

  message: any;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.message = localStorage.getItem('Message');
    localStorage.removeItem('Message');
  }

}
