import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()
export class BillsToPayPDFService {

    urlAPI = ENV.API_URL;
    header = new HttpHeaders();

    constructor(public http: HttpClient) {
        this.header.append('Content-type', 'application/json')
        this.header.append('Access-Control-Allow-Origin', '*')
    }

  getReceipt(data:string){
    var controllerRoute = "api/receipt/pdf";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { receipt: data})
        .map(data => {
            return data;
        });
  }

}
