import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CompaniesService } from '../services/companies.service';
import { AlertService } from '../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerDataSource } from 'ng2-smart-table';
import { DetalhefornecedorComponent } from './detalhefornecedor/detalhefornecedor.component';
import _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Component({
  selector: 'app-cadastrofornecedor',
  templateUrl: './cadastrofornecedor.component.html',
  styleUrls: ['./cadastrofornecedor.component.css']
})
export class CadastrofornecedorComponent implements OnInit {

  @ViewChild('grdTags') grdTags;

  faFileExcel = faFileExcel;
  currentUser: any;
  companies: any;
  loading = false;
  companyshow: any;
  hideCols: any;
  keys: any;
  viewShowInfoAdc = false;
  permissions: any;
  showpselectpag = false;
  pagination = false;
  selectPage: boolean = false;

  settings;
  mySettings = {
    actions: false,
    columns: {
      name1: {
        title: 'Razão Social',
        isSelected: true
      },
      stcd1: {
        title: 'CNPJ',
        valuePrepareFunction: (cell, row) => { return row.stcd1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); },
        isSelected: true
      },
      lifnr: {
        title: 'Cod. Fornecedor',
        isSelected: true
      },
      button: {
        title: '',
        type: 'custom',
        renderComponent: DetalhefornecedorComponent,
        filter: false,
        isSelected: true
      },
    },
    pager: {
      display: true,
      perPage: 10
    }
  };

  source: ServerDataSource;
  pageOptions: any[];

  constructor(private companiesService: CompaniesService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private cdRef: ChangeDetectorRef) {
    authService.verifyPermissionCadastroFornecedor();

    this.settings = Object.assign({}, this.mySettings)
    this.keys = Object.keys;

    this.pageOptions = [10, 50, 100];
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    this.getcompanies();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getcompanies() {
    this.loading = true;
    this.companiesService.getCompanies(this.currentUser.supplier_id)
      .subscribe(
        data => {
          this.companies = Object.keys(data.companies).map(
            function (key) {

              for (var i = 0; i < data.companies.length; i++) {
                data.companies[i].stcd1 = data.companies[i].stcd1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
              }

              return data.companies[key];
            });

          this.source = _.orderBy(this.companies, [
            { propertyName: "stcd1", order: "asc" }
          ]);

          //select qtd por página
          if (this.companies.length > 10) {
            this.showpselectpag = true;
          }
          else {
            this.showpselectpag = false;
            this.pagination = false;
            this.selectPage = false;
            this.mySettings.pager.perPage = 10;
            this.settings = Object.assign({}, this.mySettings);
          }

          //paginação genérica
          if (this.settings.pager.perPage < this.companies.length) {
            this.pagination = false;
          }
          else if ((this.settings.pager.perPage > this.companies.length) && (this.selectPage)) {
            this.pagination = true;
          }

          this.loading = false;
        },
        error => {
          this.alertService.error("Erro ao buscar dados.");
          this.loading = false;
        });
  }

  detalheregistro(user, content) {
    //this.loading = true;
    this.companyshow = user;

    this.modalService.open(content, { ariaLabelledBy: 'modal-detalhe', size: 'lg' });
  }

  public updateCols($event) {

    this.hideCols = '';
    let i = 0;
    for (const colI in this.settings.columns) {
      if (this.settings.columns[colI].isSelected) {
        i++;
      } else {
        i++;
        this.hideCols += `hd-cl-${i} `;
      }
    }
  }

  ShowInfoAdc() {
    if (!this.viewShowInfoAdc)
      this.viewShowInfoAdc = true;
    else
      this.viewShowInfoAdc = false;
  }

  downloadExcel() {
    this.loading = true;

    this.companiesService.downloadExcel(this.currentUser.supplier_id)
      .subscribe(
        data => {

          this.loading = false;

          let arquivo = data.toString();

          var stringBinary = window.atob(arquivo);
          var binarySize = stringBinary.length;
          var bytes = new Uint8Array(binarySize);
          for (var i = 0; i < binarySize; i++) {
            var ascii = stringBinary.charCodeAt(i);
            bytes[i] = ascii;
          }

          const blob = new Blob([bytes], { type: 'application/xlsx' });
          const dataURL = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = dataURL;
          link.download = "companies.xlsx";

          //alteração para funcionar no IE(Funciona nos outros também)
          var event = new MouseEvent('click');
          link.dispatchEvent(event);

        }
        , (err) => {
          alert("Erro ao baixar o excel.");
        });
  }

  onChangePageRows(rowsperpage) {
    this.selectPage = true;
    this.pagination = false;
    this.mySettings.pager.perPage = rowsperpage;
    this.settings = Object.assign({}, this.mySettings);

    this.getcompanies();
  }

}
