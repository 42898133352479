import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalhefornecedor',
  templateUrl: './detalhefornecedor.component.html',
})
export class DetalhefornecedorComponent implements OnInit {

  public renderValue;

  companyshow: any;
  @Input() rowData;

  constructor(private modalService :NgbModal) {  }

  ngOnInit() {
    this.renderValue = this.rowData;
  }

  detalheregistro(content)
  {      
      this.companyshow = this.renderValue;          
      this.modalService.open(content, {ariaLabelledBy: 'modal-detalhe', size: 'lg' });
  }


}