import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-detalheirf',
    templateUrl: './detalheirf.component.html',
})
export class DetalheirfComponent implements OnInit {

    public renderValue;

    data: any;
    impostos: string;

    @Input() rowData;

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
        this.renderValue = this.rowData;
        this.impostos = this.renderValue.impostos;
    }

    detalheIRF(content) {
        this.data = this.renderValue;

        this.modalService.open(content, { ariaLabelledBy: 'modal-detalheIRF' });
    }


}