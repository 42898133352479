import { Component, OnInit } from '@angular/core';
import { faKey } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidebarperfil',
  templateUrl: './sidebarperfil.component.html',
  styleUrls: ['./sidebarperfil.component.css']
})
export class SidebarperfilComponent implements OnInit {

  permissions: any;
  hasUsuariosGrupo = false;  
  faKey = faKey;
  constructor() { 

    this.permissions = JSON.parse(localStorage.getItem('Permissions'));
    let i = 0;
      for (i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i] == "ADUS") {
          this.hasUsuariosGrupo = true;
        }
      }
  }

  ngOnInit() { 

  }

}
