import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()
export class ContactsService {

    urlAPI = ENV.API_URL;
    
    constructor(private http: HttpClient) { }

    getContacts(username: string, id: string) {
        var controllerRoute = "api/contacts";
        
        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username, id: id, operation: "R" })
        .map(data => {      
                if (data.contacts) {
                    localStorage.setItem("ContactUser", JSON.stringify(data.contacts));  
                }                   
                return data;
            });
    }

}