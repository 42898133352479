import { Component, OnInit } from '@angular/core';
import { ContactsService } from '../services/contact.service';

import * as moment from 'moment';

@Component({
  selector: 'app-perfilusuario',
  templateUrl: './perfilusuario.component.html',
  styleUrls: ['./perfilusuario.component.css']
})
export class PerfilusuarioComponent implements OnInit {

  currentUser: any;
  loading = false;
  contactUser: any;

  constructor(private contactsService: ContactsService) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('CurrentUser'));   
    this.currentUser.valid_until = moment(this.currentUser.valid_until).format("DD/MM/YYYY");

    if (this.currentUser.status == "0")
      this.currentUser.status = "Ativo";
    else
      this.currentUser.status = "Bloqueado";  

      //Validação dados de contato       
      if (!localStorage.getItem('ContactUser')) 
      {
        this.getContacts();
      } 
      else
      {
        this.contactUser = JSON.parse(localStorage.getItem('ContactUser'));   
      }    
  }

  getContacts(){
    this.loading = true;
    this.contactsService.getContacts(this.currentUser.username, this.currentUser.supplier_id)
    .subscribe(
      data => {
        this.contactUser = Object.keys(data.contacts).map(function (key) { return data.contacts[key]; });
        this.loading = false;
      },
      error => {
          alert("Erro ao buscar dados do contato");
          this.loading = false;
      });
  }
}
