import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-renovarsenha',
  templateUrl: './renovarsenha.component.html',
  styleUrls: ['./renovarsenha.component.css']
})
export class RenovarsenhaComponent implements OnInit {


  model: any = {};
  loading = false;
  returnUrl: string;
  closeResult: string;
  ShowHidePass = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService, private modalService: NgbModal) { }

  ngOnInit() {
  }

  ExibirSenha() {
    this.ShowHidePass = !this.ShowHidePass;
  }

  resetarSenha() {
    //Campo senha divergente do campo de confirmação.
    //Usuário GTAC_FOR não cadastrado no portal
    //Chave inválida para mudança de senha: ssss

    this.loading = true;

    if (!(this.model.password == this.model.passwordc)) {
      this.alertService.error("Nova Senha e Confirmar Nova Senha, devem ser iguais!");
      this.loading = false;
      return;
    }

    this.authenticationService.renewpassword(this.model.username, this.model.password, this.model.codvalidation).subscribe(
      data => {
        if (data.message == "Senha Alterada com Sucesso") {
          this.alertService.success("Senha Alterada com Sucesso");
        }       
        this.loading = false;
      },
      error => {
        if (error.error)
          if (!error.error.message)
            //Quando não é possível acessar a api
            this.alertService.error("Erro de comunicação com o servidor.");
          else
            this.alertService.error(error.error.message);
        else
          this.alertService.error("Erro ao tentar alterar a senha.");
        this.loading = false;
      });
  }
}
