import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import _ from 'lodash';
import { Router } from '@angular/router';

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()
export class AuthenticationService {

    urlAPI = ENV.API_URL;

    constructor(private http: HttpClient,
                private router: Router) { }

    login(username: string, password: string) {
        var controllerRoute = "api/login";

        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username, password: password })
            .map(data => {
                if (data.user) {
                    localStorage.setItem("CurrentUser", JSON.stringify(data.user));
                    localStorage.setItem("Permissions", JSON.stringify(data.permissions));
                    localStorage.setItem("Suppliers", JSON.stringify(data.suppliers));
                }
                return data;
            });
    }

    logout() {
        localStorage.clear();
    }

    changepassword(username: string, password: string) {
        var controllerRoute = "api/password/change";
        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username, password: password })
            .map(data => {
                return data;
            });
    }

    renewpassword(username: string, password: string, key: string) {
        var controllerRoute = "api/password/change";
        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username, password: password, key: key })
            .map(data => {
                return data;
            });
    }

    termsagree(username: string) {
        var controllerRoute = "api/terms/agree";
        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username })
            .map(data => {
                return data;
            });
    }

    resetpassword(username: string) {
        var controllerRoute = "api/password/reset";
        return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { username: username })
            .map(data => {
                return data;
            });
    }


    //Métodos para verificar permissões
    
    verifyPermissionCadastroUusuariosGrupo(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) {return arrayValue === "ADUS";})) != "ADUS") {
            this.router.navigate(['\dashboard']);
        }
    }

    verifyPermissionContasaPagar(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) { return arrayValue === "PTIT"; })) != "PTIT") {
            this.router.navigate(['\dashboard']);
        }
    }

    verifyPermissionContato(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) { return arrayValue === "MAIL"; })) != "MAIL") {
            this.router.navigate(['\dashboard']);
        }
    }

    verifyPermissionSolicitarAntecipacao(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) { return arrayValue === "SOAF"; })) != "SOAF") {
            this.router.navigate(['\dashboard']);
        }
    }

    verifyPermissionAcompanharAntecipacao(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) { return arrayValue === "ACAF"; })) != "ACAF") {
            this.router.navigate(['\dashboard']);
        }
    }

    verifyPermissionCadastroFornecedor(){
        let permissions = JSON.parse(localStorage.getItem('Permissions'));
        if ((_.find(permissions, function (arrayValue) {return arrayValue === "ADFN";})) != "ADFN") {
            this.router.navigate(['\dashboard']);
        }
    }
}