import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

//Variáveis de configuração
import { ENV } from '../config/config';

@Injectable()
export class AnticipationService {

  urlAPI = ENV.API_URL;
  header = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.header.append('Content-type', 'application/json')
    this.header.append('Access-Control-Allow-Origin', '*')
  }


  getCompanies(id: string) {
    var controllerRoute = "api/companies";
    return this.http.post<any>(this.urlAPI + '/' + controllerRoute, { sign: "I", option: "EQ", low: id })
      .map(data => {
        return data;
      });
  }

  getAvailable(data) {
    return new Promise((resolve, reject) => {
      let url = this.urlAPI + '/api/anticipation/available';

      setTimeout(function() {
        this.http.post(url, JSON.stringify(data), { headers: this.header }).subscribe(
          res => { resolve(res); },
          err => { reject(err); }
        );
      }.bind(this), 1500);

    });
  }

  getFollowUps(data) {
    return new Promise((resolve, reject) => {
      let url = this.urlAPI + '/api/anticipation/follow-up';

      if (data.status.length == 0 || data.status[0] == 0) {
        data.status = ['P', 'A', 'R'];
      }

      this.http.post(url, JSON.stringify(data), { headers: this.header }).subscribe(
        res => { resolve(res); },
        err => { reject(err); }
      );
    });
  }

  send(data) {
    return new Promise((resolve, reject) => {
      let url = this.urlAPI + '/api/anticipation/send';

      this.http.post(url, JSON.stringify(data), { headers: this.header }).subscribe(
        res => { resolve(res); },
        err => { reject(err); }
      );
    });
  }

  getReport(data) {
    return new Promise((resolve, reject) => {
      let url = this.urlAPI + '/api/anticipation/excelreport';
      
      this.http.post(url, JSON.stringify(data), { headers: this.header }).subscribe(
        res => { resolve(res); },
        err => { reject(err); }
      );
    });
  }

  getReportfollowup(data) {
    return new Promise((resolve, reject) => {
      let url = this.urlAPI + '/api/anticipation/followup/excelreport';
      
      this.http.post(url, JSON.stringify(data), { headers: this.header }).subscribe(
        res => { resolve(res); },
        err => { reject(err); }
      );
    });
  }
  
}