import { EnvServiceProvider } from '../services/env/env.service.provider';

export const ENV = {

    API_URL: EnvServiceProvider.useFactory().API_URL,
        
    PROD_URL: 'https://portalfornecedordecathlon.aevee.com.br',

}


